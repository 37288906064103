<template>
  <section class="Hero-restaurant" role="banner">
    <ul class="Hero-restaurant__images">
      <template v-if="medias.length">
        <li :key="`image-${media.formats?.[0]?.path}`" class="Hero-restaurant__image" v-for="media in medias.slice(0, 3)">
          <ux-atoms-image :media="media" fetch-priority="high" loading="eager" preload />
        </li>
      </template>
      <li class="Hero-restaurant__image" v-else>
        <ux-atoms-image fetch-priority="high" loading="eager" />
      </li>
    </ul>
    <div class="Hero-restaurant__controls" v-if="medias.length > 1">
      <ux-atoms-button :aria-label="$t('ux.molecules.heroRestaurant.openGallery')" @click="toggleModalFull" size="xs" variant="preview">
        <span class="Hero-restaurant__control Hero-restaurant__control--small">
          <ux-atoms-icon name="picture" size="s" />
          <ux-atoms-typo :text="medias?.length.toString()" as="span" color="inherit" variant="text-xsmall" />
        </span>
        <span class="Hero-restaurant__control Hero-restaurant__control--large">
          <ux-atoms-typo
            :text="$t('ux.molecules.heroRestaurant.buttonLabel', { number: medias?.length })"
            as="span"
            color="inherit"
            variant="text-medium"
          />
        </span>
      </ux-atoms-button>
    </div>
    <Teleport to="#teleports">
      <ux-molecules-modal :event-element="modalTarget" header-logo icon-name="arrow-left" v-model="modalFullIsOpen">
        <template #header>
          <ux-atoms-image
            :alt="$t('ux.molecules.heroRestaurant.logoAlt', { restaurantName: restaurantName })"
            :src="restaurantLogo"
            class="Hero-restaurant__modal-header-logo"
            contain
            v-if="restaurantLogo"
          />
          <ux-atoms-typo
            :text="restaurantName"
            as="p"
            class="Hero-restaurant__modal-header-text"
            color="inherit"
            v-else-if="restaurantName"
            variant="text-medium"
          />
        </template>
        <ux-molecules-grid-images :medias="medias" />
      </ux-molecules-modal>
    </Teleport>
  </section>
</template>
<script setup lang="ts">
import type { RestaurantMedia } from '~/graphql/schema';

export interface HeroRestaurantProps {
  medias: RestaurantMedia[] | null;
  restaurantLogo?: null | string;
  restaurantName?: null | string;
}

withDefaults(defineProps<HeroRestaurantProps>(), {
  medias: null,
  restaurantLogo: undefined,
  restaurantName: undefined
});

const modalTarget = ref<HTMLElement | null>(null);
const modalFullIsOpen = ref<boolean>(false);

const toggleModalFull = (event: Event) => {
  modalTarget.value = event.target as HTMLElement;
  modalFullIsOpen.value = !modalFullIsOpen.value;
};
</script>
<style lang="scss" scoped>
@use 'HeroRestaurant.scss';
</style>
