<template>
  <template v-if="menuType === MenuType.MenuModo">
    <ux-atoms-button
      :full="!isNav"
      :size="isNav ? 's' : 'm'"
      :variant="variant"
      @click="toggleRestaurantMenuModal"
      class="Restaurant-menu"
      data-modal="modal_menumodo"
    >
      <ux-atoms-icon name="menu" size="m" v-if="displayIcon" />
      {{ $t('ux.molecules.restaurantMenu.seeMenu') }}
    </ux-atoms-button>
    <Teleport to="#teleports">
      <ux-molecules-modal
        :disable-header-height="disableHeaderHeight"
        :event-element="modalTarget"
        button-no-border
        button-position="right"
        has-label-id
        id="modal_menumodo"
        size="large"
        v-model="restaurantMenuModalIsOpen"
      >
        <iframe
          :src="`https://www.menumodo.com/app.php/viewer/client_outlet/${restaurantCode}?tags=rb&lang=${$i18n.locale}&designID=accor_rb`"
          data-modal-menumodo
          tabindex="0"
          v-if="restaurantMenuModalIsOpen"
        />
      </ux-molecules-modal>
    </Teleport>
  </template>
  <template v-else-if="redirectButton">
    <ux-atoms-link
      :href="redirectButtonUrl"
      :title="$t('ux.molecules.restaurantMenu.seeMenuOnRestaurantWebsite')"
      @click="gtmMenuEvent"
      class="Restaurant-menu__link"
      rel="noopener external"
      target="_blank"
      v-if="redirectButtonUrl"
    >
      <ux-atoms-button
        :full="!isNav"
        :label="$t('ux.molecules.restaurantMenu.seeMenu')"
        :size="isNav ? 's' : 'm'"
        :variant="variant"
        class="Restaurant-menu"
        is-decorative
      >
        <ux-atoms-icon name="menu" size="m" v-if="displayIcon" />
        {{ $t('ux.molecules.restaurantMenu.seeMenu') }}
      </ux-atoms-button>
    </ux-atoms-link>
  </template>
  <div class="Restaurant-menu__xss-menus" v-else-if="dropdownButton">
    <ux-atoms-button
      :aria-expanded="menuDropdownSelectorIsOpen"
      :full="!isNav"
      :size="isNav ? 's' : 'm'"
      :variant="variant"
      @click="toggleRestaurantMenuDropdown"
      aria-controls="Restaurant-menu__xss-menus__content"
      aria-haspopup="true"
      class="Restaurant-menu"
    >
      <ux-atoms-icon name="menu" size="m" v-if="displayIcon" />
      {{ $t('ux.molecules.restaurantMenu.seeMenu', { count: 2 }) }}
      <ux-atoms-icon
        :class="['Restaurant-menu__xxs-chevron', { 'Restaurant-menu__xxs-chevron--reverse': menuDropdownSelectorIsOpen }]"
        name="chevron-down"
        size="s"
      />
    </ux-atoms-button>
    <ux-molecules-modal-dropdown
      :event-element="menuDropdownButton"
      :model-value="menuDropdownSelectorIsOpen"
      @modal-dropdown:model-value="updateMenuDropdownSelectorIsOpen"
      v-if="dropdownButton"
    >
      <ul
        @keydown.down="focusNextListItem($event)"
        @keydown.up="focusPreviousListItem($event)"
        class="Restaurant-menu__xss-menus__content"
        id="Restaurant-menu__xss-menus__content"
        ref="xssMenusContent"
        role="menu"
      >
        <li :key="`${menu}-menu.storageUrl`" role="menuitem" v-for="menu in menus">
          <ux-atoms-link
            :href="menu.storageUrl"
            :title="$t('ux.molecules.restaurantMenu.resdiaryMenu', { label: menu.name })"
            @click="gtmMenuEvent"
            class="Restaurant-menu__link"
            rel="noopener external"
            target="_blank"
            underline
            v-if="menu.storageUrl"
          >
            {{ menu.name }}
          </ux-atoms-link>
        </li>
      </ul>
    </ux-molecules-modal-dropdown>
  </div>
</template>
<script setup lang="ts">
import { ButtonVariant } from '~/components/ux/atoms/Button/types';
import { MenuType, RestaurantMenu } from '~/graphql';
import { getFocusableElements, getNextFocusableElement, getPreviousFocusableElement } from '~/helpers/accessibility';
import { gtmEvent } from '~/helpers/gtm';

export interface RestaurantMenuProps {
  displayIcon?: boolean;
  externalMenuUrl?: null | string;
  isNav?: boolean;
  menuType?: MenuType | null;
  menus?: RestaurantMenu[] | null;
  variant?: ButtonVariant;
}

const props = withDefaults(defineProps<RestaurantMenuProps>(), {
  displayIcon: false,
  externalMenuUrl: undefined,
  externalMenus: undefined,
  isNav: false,
  menuType: undefined,
  menus: undefined,
  variant: 'outlined'
});

const { isTablet } = useCurrentWindowSize();

const disableHeaderHeight = ref<boolean>(true);

const restaurantCode = useRoute()?.params.restaurantCode;
const menuDropdownButton = ref<HTMLElement | null>(null);
const restaurantMenuModalIsOpen = ref<boolean>(false);
const menuDropdownSelectorIsOpen = ref<boolean>(false);
const modalTarget = ref<HTMLElement | null>(null);
const xssMenusContent = ref<HTMLElement | null>(null);
const focusableListElements = ref<HTMLElement[]>();

const toggleRestaurantMenuModal = (event: Event) => {
  modalTarget.value = event.target as HTMLElement;
  restaurantMenuModalIsOpen.value = !restaurantMenuModalIsOpen.value;
  gtmMenuEvent();
};

const gtmMenuEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'view menu',
    bloc_name: 'main bloc',
    pagename: 'restaurantsandbars::restaurantdetails'
  });
};

const toggleRestaurantMenuDropdown = (event: Event) => {
  menuDropdownButton.value = event.target as HTMLElement;
  nextTick(() => {
    menuDropdownSelectorIsOpen.value = !menuDropdownSelectorIsOpen.value;
  });
};

const updateMenuDropdownSelectorIsOpen = (value: boolean) => {
  menuDropdownSelectorIsOpen.value = value;
};

const redirectButton = computed(
  () => (props.menuType === MenuType.External && props.externalMenuUrl) || (props.menuType === MenuType.Api && props.menus?.length === 1)
);

const redirectButtonUrl = computed(() => {
  if (props.menuType === MenuType.External) {
    return props.externalMenuUrl;
  }

  if (props.menuType === MenuType.Api && props.menus?.length === 1) {
    return props.menus[0].storageUrl;
  }

  return '';
});

const getListFocusableElements = () => {
  focusableListElements.value = getFocusableElements(xssMenusContent.value);
};

const focusPreviousListItem = (e: Event) => {
  e.preventDefault();
  const previousEl = getPreviousFocusableElement(e.target, focusableListElements.value);
  previousEl.focus();
};

const focusNextListItem = (e: Event) => {
  e.preventDefault();
  const nextEl = getNextFocusableElement(e.target, focusableListElements.value);
  nextEl.focus();
};

const dropdownButton = computed(() => props.menuType === MenuType.Api && props.menus && props.menus.length > 1);

watch(
  () => isTablet.value,
  () => {
    disableHeaderHeight.value = !isTablet.value;
  }
);

onMounted(() => {
  if (dropdownButton.value) {
    getListFocusableElements();
  }
});
</script>
<style lang="scss" scoped>
@use 'RestaurantMenu.scss';
</style>
