<template>
  <div aria-live="polite" class="Restaurant-descriptions__paragraph">
    <ux-atoms-typo as="p" class="Restaurant-descriptions__inline" v-if="veryLongDescriptionPart1">
      {{ veryLongDescriptionPart1 }}&nbsp;
    </ux-atoms-typo>
    <ux-atoms-typo
      :class="[{ 'Restaurant-descriptions__paragraph--truncate': !veryLongDescriptionPart1 && !openingReadMoreDescription }]"
      :id="id"
      as="p"
      v-show="(!veryLongDescriptionPart1 && !openingReadMoreDescription) || openingReadMoreDescription"
    >
      {{ veryLongDescriptionPart2 }}
    </ux-atoms-typo>
    <ux-atoms-button
      :aria-controls="id"
      @click="switchOpeningReadMoreDescription"
      class="Restaurant-descriptions__link Restaurant-descriptions__inline"
      variant="link"
    >
      {{ buttonContent }}
    </ux-atoms-button>
  </div>
</template>
<script setup lang="ts">
export interface RestaurantDescriptionsProps {
  veryLongDescriptionPart1: string;
  veryLongDescriptionPart2: string;
}

defineProps<RestaurantDescriptionsProps>();

const openingReadMoreDescription = ref<boolean>(false);

const switchOpeningReadMoreDescription = () => {
  openingReadMoreDescription.value = !openingReadMoreDescription.value;
};

const id = useId();
const { t } = useI18n();

const buttonContent = computed(() => {
  return openingReadMoreDescription.value ? t('ux.molecules.restaurantDescriptions.readLess') : t('ux.molecules.restaurantDescriptions.readMore');
});
</script>
<style lang="scss" scoped>
@use 'RestaurantDescriptions.scss';
</style>
