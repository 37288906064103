<template>
  <div aria-hidden="true" class="Restaurant-availabilities-content-skeleton__container">
    <div class="Restaurant-availabilities-content-skeleton__line"></div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@use './RestaurantAvailabilitiesContentSkeleton.scss';
</style>
