<template>
  <div
    :class="[
      'Phone-input',
      {
        'Phone-input--error': error
      }
    ]"
  >
    <ux-atoms-typo :for="id" as="label" class="Phone-input-label">
      {{ $t('ux.organismes.customerForm.form.phoneNumber.label', { phoneNumber: phoneExample }) }}
    </ux-atoms-typo>
    <div :class="['Phone-input__container']">
      <vue-tel-input
        :auto-default-country="phoneOptions.autoDefaultCountry"
        :class="['Phone-input__container-input', { 'Phone-input__container-input--focus-visible': hasFocusVisible }]"
        :dropdown-options="phoneOptions.dropdownOptions"
        :id="id"
        :input-options="phoneOptions.inputOptions"
        :mode="phoneOptions.mode"
        :valid-characters-only="true"
        @country-changed="onCountryChanged"
        @focusout="removeFocusVisible"
        @keyup="addFocusVisible($event)"
        @validate="onValidityChanged"
        v-model="value"
      />
    </div>
    <ux-atoms-typo
      :id="`Phone-input-${name}-error-text`"
      :text="$t(errorText, { phoneNumber: phoneExample })"
      as="p"
      class="Phone-input__error"
      v-if="error && errorText"
    />
  </div>
</template>

<script lang="ts" setup>
import { getExample } from 'awesome-phonenumber';
// eslint-disable-next-line import/no-unassigned-import
import 'vue-tel-input/vue-tel-input.css';

export interface PhoneNumberInputProps {
  error?: boolean;
  errorText?: string;
  id: string;
  modelValue: null | string;
  name: string;
}

const props = withDefaults(defineProps<PhoneNumberInputProps>(), {
  error: false,
  errorText: undefined
});

const emit = defineEmits(['update:modelValue', 'validity-changed']);

const phoneExample = ref<string | undefined>();
const hasFocusVisible = ref<boolean>(false);

const value = useVModel(props, 'modelValue', emit);

const ariaDescribedBy = computed(() => {
  if (props.error) {
    return `phone-number-input-${props.name}-error-text`;
  }

  return undefined;
});

function onCountryChanged(country: Record<string, string>) {
  const example = getExample(country.iso2);
  phoneExample.value = example.number?.international;
}

function onValidityChanged(phoneObject: Record<string, string>) {
  emit('validity-changed', phoneObject.valid);
}

const phoneOptions = computed(() => ({
  autoDefaultCountry: true,
  dropdownOptions: {
    showDialCodeInList: true,
    showDialCodeInSelection: false,
    showFlags: true,
    tabindex: 0
  },
  inputOptions: {
    'aria-describedby': ariaDescribedBy.value,
    placeholder: phoneExample.value,
    required: true,
    styleClasses: ['Phone-input__container-input'],
    tabindex: 0
  },
  mode: 'international'
}));

const addFocusVisible = (e: Event) => {
  // We ensure the focus was given through the tab focus
  const targetElement = e.target as HTMLElement;

  if (e.keyCode === 9 && targetElement.classList.contains('vti__input')) {
    hasFocusVisible.value = true;
  }
};

const removeFocusVisible = () => {
  hasFocusVisible.value = false;
};
</script>
<style lang="scss" scoped>
@use 'PhoneNumberInput.scss';
</style>
