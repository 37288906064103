<template>
  <div class="Grid-layout Grid-layout--x-space pb-0">
    <div class="Hero-restaurant-content__layout">
      <article class="Hero-restaurant-content">
        <div class="Hero-restaurant-content__titles">
          <ux-atoms-typo :text="name" as="h1" classname="Hero-restaurant-content__title" color="dark" variant="expressive-heading-04" />
        </div>
        <div>
          <ux-atoms-typo :text="cookingTypes" as="p" color="light" v-if="cookingTypes" variant="text-regular" />
        </div>
        <div class="Hero-restaurant-content__details">
          <div class="Hero-restaurant-content__tags" v-if="displayEnrolmentPrograms">
            <ux-atoms-tag
              :label="$t(`ux.molecules.cardRestaurant.enrolmentPrograms.${enrolmentPrograms?.diningOfferParticipation.code}`)"
              variant="enrolment"
            />
          </div>
          <div
            class="Hero-restaurant-content__rating-price-content"
            v-if="(averagePrice && currencySymbol) || (averagePrice && averageRating) || averageRating"
          >
            <ux-atoms-average-price
              :currency="currencySymbol"
              :price="averagePrice"
              :underline="false"
              class="Hero-restaurant-content__price"
              has-modal
              text="text-regular"
              v-if="averagePrice && currencySymbol"
            />
            <span aria-hidden="true" class="Card-restaurant__separator" v-if="averagePrice && averageRating">•</span>
            <a
              @click.prevent="useScrollToContainer('reviews', undefined, 'start', 80)"
              class="Hero-restaurant-content__link-rating"
              href="#reviews"
              v-if="averageRating"
            >
              <ux-atoms-rating :comments="reviewCount" :stars="averageRating" :variant="RatingVariant.Full" class="Hero-restaurant-content__rating" />
            </a>
          </div>
          <ux-atoms-michelin-stars :stars="star" class="Hero-restaurant-content__michelin-stars" show-label />
          <div class="Hero-restaurant-content__info">
            <ux-atoms-button
              :title="$t('ux.molecules.heroRestaurantContent.skipToAddress')"
              @click="useScrollToContainer('address')"
              aria-hidden
              tabindex="-1"
              v-if="address"
              variant="link"
            >
              {{ address }}
            </ux-atoms-button>
            <ux-atoms-link href="#address" skip>{{ $t('ux.molecules.heroRestaurantContent.skipToAddress') }}</ux-atoms-link>
            <span aria-hidden="true" class="Card-restaurant__separator" v-if="address && schedule?.length">•</span>
            <ux-atoms-typo
              :class="`Hero-restaurant-content__status--${openingDetails.status}`"
              :text="$t(`ux.molecules.openingHours.${openingDetails.status}`)"
              as="span"
              classname="hero-restaurant-content__status"
              v-if="schedule?.length"
              variant="text-regular"
            />

            <ux-atoms-link
              @click="useScrollToContainer('schedule', undefined, 'start', 80)"
              class="Hero-restaurant-content__link"
              v-if="openingDetails.label"
            >
              {{ openingDetails.label }}
            </ux-atoms-link>

            <ux-atoms-link href="#schedule" skip>{{ $t('ux.molecules.heroRestaurantContent.skipToSchedule') }}</ux-atoms-link>

            <ux-atoms-button @click="toggleOpeningHoursModal" class="Hero-restaurant-content__button" v-if="openingDetails.label" variant="link">
              {{ openingDetails.label }}
            </ux-atoms-button>
          </div>
        </div>
        <Teleport to="#teleports">
          <ux-molecules-modal
            :event-element="modalTarget"
            button-no-border
            button-position="right"
            has-label-id
            header-text="Opening hours"
            size="small"
            v-model="openingHoursModalOpen"
          >
            <template #header>
              <ux-atoms-typo as="p" color="dark" variant="text-heading-01">{{ $t('ux.molecules.openingHours.openingHours') }}</ux-atoms-typo>
            </template>
            <ux-molecules-opening-hours
              :closures="exceptionalClosures"
              :restaurant-timezone="restaurantTimezone"
              :schedule="schedule"
              is-modal
              single-col
            />
          </ux-molecules-modal>
        </Teleport>
      </article>
      <div class="Hero-restaurant-content__availabilities">
        <ux-molecules-restaurant-availabilities
          :booking-widget-url="bookingWidgetUrl"
          :currency-symbol="currencySymbol"
          :external-menu-url="externalMenuUrl"
          :has-partners="hasPartners"
          :identifiers="identifiers"
          :initial-availabilities="initialAvailabilities"
          :menu-type="menuType"
          :menus="menus"
          :offers="offers"
          :partner-id="partnerId"
          :phone="phone"
          :phone-prefix="phonePrefix"
          :terms-and-conditions="termsAndConditions"
        />
      </div>
    </div>
  </div>
  <ux-molecules-restaurant-nav
    :active-tabs="activeTabs"
    :currency-symbol="currencySymbol"
    :external-menu-url="externalMenuUrl"
    :has-partners="hasPartners"
    :menu-type="menuType"
    :menus="menus"
    :partner-id="partnerId"
    :terms-and-conditions="termsAndConditions"
    @navigate-content="handleNavigate"
  />
</template>
<script setup lang="ts">
import { format, utcToZonedTime } from 'date-fns-tz';

import type {
  MenuType,
  Period,
  RestaurantIdentifier,
  RestaurantMenu,
  RestaurantOffers,
  ScheduleOpenedDay,
  SingleAvailabilitiesQuery
} from '~/graphql/schema';

import { EnrolmentPrograms } from '~/components/ux/molecules/CardRestaurant/types';
import { RatingVariant } from '~/core';
import { DEFAULT_TIMEZONE } from '~/core/constants';
import { useScrollToContainer } from '~/helpers/scrollToContainer';

export interface HeroRestaurantContentProps {
  activeTabs?: string[];
  averagePrice?: number;
  averageRating?: null | number;
  bookingWidgetUrl?: null | string;
  city?: string;
  countryCode?: string;
  currencyLabel?: null | string;
  currencySymbol?: null | string;
  enrolmentPrograms?: EnrolmentPrograms;
  exceptionalClosures?: Period[];
  externalMenuUrl?: null | string;
  facetCodes?: string[];
  foodType?: string;
  identifiers?: RestaurantIdentifier[] | null;
  initialAvailabilities?: {
    data: SingleAvailabilitiesQuery['singleAvailabilities'] | null;
    loading: boolean;
  };
  menuType?: MenuType | null;
  menus?: RestaurantMenu[] | null;
  name?: null | string;
  offers?: RestaurantOffers[];
  partnerId?: string;
  phone?: string;
  phonePrefix?: string;
  restaurantTimezone?: string;
  reviewCount?: number;
  schedule?: ScheduleOpenedDay[];
  star?: number;
  termsAndConditions?: string;
  zipCode?: string;
}

enum OpeningStatus {
  CLOSED = 'closed',
  OPENED = 'opened'
}

const props = withDefaults(defineProps<HeroRestaurantContentProps>(), {
  activeTabs: undefined,
  averagePrice: undefined,
  averageRating: undefined,
  bookingWidgetUrl: undefined,
  city: undefined,
  countryCode: undefined,
  currencyLabel: undefined,
  currencySymbol: undefined,
  enrolmentPrograms: undefined,
  exceptionalClosures: undefined,
  externalMenuUrl: undefined,
  facetCodes: undefined,
  foodType: undefined,
  identifiers: undefined,
  initialAvailabilities: () => ({
    data: null,
    loading: true
  }),
  menuType: undefined,
  menus: undefined,
  name: undefined,
  offers: undefined,
  partnerId: undefined,
  phone: undefined,
  phonePrefix: undefined,
  restaurantTimezone: DEFAULT_TIMEZONE,
  reviewCount: undefined,
  schedule: undefined,
  star: undefined,
  termsAndConditions: undefined,
  zipCode: undefined
});

const emit = defineEmits(['navigateContent']);

const handleNavigate = (tabId: string) => {
  emit('navigateContent', tabId);
};

const now = new Date();
const { t } = useI18n();

const currentUTCDay = now.getUTCDay();

const displayEnrolmentPrograms = computed(
  () => props.enrolmentPrograms && ['LEARN', 'LEARNP'].includes(props.enrolmentPrograms.diningOfferParticipation.code)
);
const userLocalTime = utcToZonedTime(now, props.restaurantTimezone);
const formattedUserLocalTime = format(userLocalTime, 'HH:mm');

const cookingTypes = computed(() => {
  const mainThematic: string | undefined = props.facetCodes && props.facetCodes.length > 0 ? props.facetCodes[0] : undefined;
  const result: string =
    (props.foodType ?? '') +
    (props.foodType && mainThematic ? ', ' : '') +
    (mainThematic ? t(`ux.molecules.cardRestaurant.mainThematics.${mainThematic}`) : '');

  return result !== '' ? result : undefined;
});

const address = computed(() => {
  if (!props.city || !props.zipCode) {
    return '';
  }
  const res = `${props.city} ${props.zipCode}`;

  if (res.length > 12) {
    return `${res.slice(0, 12)}...`;
  }

  return res;
});

const openingDetails = computed(() => {
  const details = {
    label: '',
    status: ''
  };

  const filteredSchedule = props.schedule?.filter(({ day }) => !['BANK_HOLIDAYS', 'WEEKEND_AND_BANK_HOLIDAYS'].includes(day));

  if (filteredSchedule?.length && filteredSchedule.every((schedule) => schedule.openingHours === null)) {
    details.status = OpeningStatus.OPENED;
    details.label = t('ux.molecules.openingHours.opensAt');
    return details;
  }

  const nextOpening = useRestaurantNextOpening(filteredSchedule as ScheduleOpenedDay[], currentUTCDay, formattedUserLocalTime);
  if (!nextOpening?.value) {
    details.status = OpeningStatus.CLOSED;
    return details;
  }

  switch (nextOpening.value?.dayIndex) {
    case currentUTCDay:
      if (nextOpening.value?.from) {
        details.status = OpeningStatus.CLOSED;
        details.label = t('ux.molecules.openingHours.opensAt', {
          time: nextOpening.value.from
        });
      } else {
        details.status = OpeningStatus.OPENED;
        details.label = t('ux.molecules.openingHours.openNow', {
          time: nextOpening.value.to
        });
      }
      break;
    case currentUTCDay === 6 ? 0 : currentUTCDay + 1: // Handling the next day scenario, considering week wrap-around
      details.status = OpeningStatus.CLOSED;
      details.label = t('ux.molecules.openingHours.openTomorrow', {
        time: nextOpening.value.from
      });
      break;
    default:
      details.status = OpeningStatus.CLOSED;
      details.label = t('ux.molecules.openingHours.openNext', {
        day: t(`days.${nextOpening.value?.dayLabel.toLowerCase()}`),
        time: nextOpening.value?.from
      });
  }
  return details;
});

const hasPartners = computed(() => !!(props.identifiers?.some((identifier) => identifier.system) || (props.partnerId && props.bookingWidgetUrl)));

const openingHoursModalOpen = ref<boolean>(false);
const modalTarget = ref<HTMLElement | null>(null);

const toggleOpeningHoursModal = (event: Event) => {
  modalTarget.value = event.target as HTMLElement;
  openingHoursModalOpen.value = !openingHoursModalOpen.value;
};
</script>
<style lang="scss" scoped>
@use 'HeroRestaurantContent.scss';
</style>
