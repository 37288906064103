<template>
  <nav
    :aria-label="$t('ux.molecules.restaurantNav.aria')"
    :class="['Restaurant-nav', { 'Restaurant-nav--sticky': restaurantNavIsSticky }]"
    ref="restaurantNav"
    role="navigation"
  >
    <div class="Grid-layout Restaurant-nav__layout">
      <div class="Grid-twelve--restaurant Grid-twelve Grid-twelve--subgrid">
        <div class="Restaurant-nav__list">
          <ux-organismes-tabs :tabs="activeTabs" @navigate-content="handleNavigate" />
        </div>
        <ux-molecules-restaurant-actions
          :booking-type="bookingType"
          :currency-symbol="currencySymbol"
          :external-menu-url="externalMenuUrl"
          :has-partners="hasPartners"
          :menu-type="menuType"
          :menus="menus"
          :partner-id="partnerId"
          :terms-and-conditions="termsAndConditions"
          class="Restaurant-nav__actions"
          is-nav
          v-if="displayActions"
        />
      </div>
    </div>
  </nav>
  <div class="Restaurant-nav__border"></div>
</template>
<script setup lang="ts">
import { MenuType, RestaurantMenu } from '~/graphql';

import { navTabs } from './types';

export interface RestaurantNavProps {
  activeTabs?: string[];
  bookingType?: 'CMS' | 'XSS' | undefined;
  currencySymbol?: null | string;
  externalMenuUrl?: null | string;
  hasPartners?: boolean;
  menuType?: MenuType | null;
  menus?: RestaurantMenu[] | null;
  partnerId?: string;
  termsAndConditions?: string;
}

const props = withDefaults(defineProps<RestaurantNavProps>(), {
  activeTabs: undefined,
  bookingType: 'XSS',
  currencySymbol: undefined,
  externalMenuUrl: undefined,
  hasPartners: undefined,
  menuType: undefined,
  menus: undefined,
  partnerId: undefined,
  termsAndConditions: undefined
});

const { t } = useI18n();

const emit = defineEmits(['navigateContent']);

const handleNavigate = (tabId: string) => {
  emit('navigateContent', tabId);
};

const restaurantNav = ref();
const restaurantNavIsSticky = ref(false);

const { isDesktop } = useCurrentWindowSize();

const displayActions = computed(() => {
  return isDesktop.value && restaurantNavIsSticky.value;
});

const tabs = [
  { href: '#offers', id: navTabs.Offers, label: t('ux.molecules.restaurantNav.offers') },
  { href: '#menu', id: navTabs.Menu, label: t('ux.molecules.restaurantNav.menu') },
  { href: '#reviews', id: navTabs.Reviews, label: t('ux.molecules.restaurantNav.reviews') },
  { href: '#contact', id: navTabs.Contact, label: t('ux.molecules.restaurantNav.contact') }
];

const activeTabs = computed(() => tabs.filter((tab) => props.activeTabs?.includes(tab.id)));

const observeNavIntersection = () => {
  const observer = new window.IntersectionObserver(
    ([nav]) => {
      restaurantNavIsSticky.value = nav.intersectionRatio > 0 && nav.boundingClientRect.top < 0;
    },
    { threshold: [1] }
  );
  observer.observe(restaurantNav.value);
};

onMounted(() => {
  observeNavIntersection();
});
</script>
<style lang="scss" scoped>
@use 'RestaurantNav.scss';
</style>
