<template>
  <div class="Booking-offers">
    <div class="Booking-offers__header">
      <ux-atoms-typo :text="$t('ux.molecules.bookingOffers.title')" as="p" color="dark" variant="text-heading-01" />
      <ux-atoms-typo :text="$t('ux.molecules.bookingOffers.subtitle')" as="p" color="light" variant="text-small" />
    </div>

    <ul class="Booking-offers__list">
      <li :key="`offer-${offer?.id}`" class="Booking-offers__list-item" v-for="offer of mappedOffers">
        <ux-molecules-booking-offer
          :currency-symbol="currencySymbol"
          :description="offer.description"
          :disabled="offer.disabled"
          :guests-details="{
            min: offer.minGuests,
            max: offer.maxGuests
          }"
          :id="offer.id"
          :is-accor-plus="offer.isAccorPlus"
          :is-credit-card-required="offer.requireCreditCard"
          :is-deposit-required="offer.requireDeposit"
          :is-standard="props.isStandard"
          :name="offer.name"
          :price-details="offer.price"
          :restaurant-timezone="restaurantTimezone"
          :selected-offer-id="selectedOfferId"
          :validity-details="offer.validityPeriods"
          @update:selected-offer="() => updateSelectedOffer(offer)"
        />
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { RestaurantOffers, RestaurantOffersValidity } from '~/graphql';

export interface BookingOffersProps {
  currencySymbol?: null | string;
  isStandard?: boolean;
  offers?: RestaurantOffers[];
  preSelectedOffer: RestaurantOffers | undefined;
  restaurantTimezone?: string;
}

const props = withDefaults(defineProps<BookingOffersProps>(), {
  currencySymbol: undefined,
  isStandard: true,
  offers: undefined,
  preSelectedOffer: undefined,
  restaurantTimezone: undefined
});

const userStore = useUserStore();

// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(['booking-offers::selected-offer']);
const selectedOfferId = ref<null | string>(props.preSelectedOffer?.id ?? (props.isStandard ? null : (props.offers?.[0].id as string)));

if (selectedOfferId.value) {
  const currentOffer = props.offers?.find((offer) => offer.id === selectedOfferId.value) as RestaurantOffers;
  emit('booking-offers::selected-offer', currentOffer);
}

const updateSelectedOffer = (offer: RestaurantOffers) => {
  selectedOfferId.value = offer.id as string;
  emit('booking-offers::selected-offer', offer);
};

const mappedOffers = computed(() =>
  props.offers
    ?.map((offer) => ({
      chargeAmount: offer.chargeAmount as number,
      description: offer.description as string,
      disabled:
        (offer.isAccorPlus && (!userStore.APZone || (userStore.APZone && (!userStore.isLogged || (userStore.isLogged && !userStore.isAPUser))))) ||
        undefined,
      id: offer.id as string,
      isAccorPlus: offer.isAccorPlus as boolean,
      maxGuests: offer.maxGuests as number,
      minGuests: offer.minGuests as number,
      name: offer.name as string,
      price: offer.price as number,
      requireCreditCard: offer.requireCreditCard as boolean,
      requireDeposit: offer.requireDeposit as boolean,
      validityPeriods: offer.validityPeriods as RestaurantOffersValidity[]
    }))
    .sort((a, b) => {
      if (a.disabled && !a.isAccorPlus && !b.disabled) return 1;
      if (!a.disabled && b.disabled && !b.isAccorPlus) return -1;

      if (a.isAccorPlus && !b.isAccorPlus) return -1;
      if (!a.isAccorPlus && b.isAccorPlus) return 1;

      return 0;
    })
);
</script>
<style lang="scss" scoped>
@use 'BookingOffers.scss';
</style>
