import { Maybe } from '~/core';
import { OpeningHour, ScheduleOpenedDay } from '~/graphql';

import { capitalizeFirstLetter } from './capitaliseFirstLetter';

const isOpeningHour = (value: Maybe<OpeningHour>): value is OpeningHour => {
  return value !== null && value !== undefined && typeof value.from === 'string' && typeof value.to === 'string';
};

export const transformOpeningHours = (data: ScheduleOpenedDay[]): { '@type': string; closes: string; dayOfWeek: string; opens: string }[] => {
  return data.flatMap(({ day, openingHours }) =>
    (openingHours || []).filter(isOpeningHour).map((hours) => ({
      '@type': 'OpeningHoursSpecification',
      closes: hours.to,
      dayOfWeek: capitalizeFirstLetter(day?.toLocaleLowerCase()),
      opens: hours.from
    }))
  );
};
