<template>
  <ul class="Tabs" role="tablist">
    <li
      :class="['Tab', { 'Tab--is-active': tab.isActive }]"
      :key="`tab-${tab.id}`"
      @click="(e) => handleScroll(e, tab.id)"
      class="Tabs__list"
      v-for="tab in mappedTabs"
    >
      <a :aria-selected="tab.isActive" :class="{ '--is-active': tab.isActive }" :href="tab.href" @click="tabEvent(tab.label)" class="Tabs__link">
        {{ tab.label }}
      </a>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { offsets } from '~/helpers/breakpoints';
import { detectElements } from '~/helpers/detectElements';
import { gtmEvent } from '~/helpers/gtm';
import { useScrollToContainer } from '~/helpers/scrollToContainer';

interface TabProps {
  href?: string;
  id: string;
  label: string;
}

interface TabsProps {
  activeTab?: string;
  tabs: TabProps[];
}

const props = withDefaults(defineProps<TabsProps>(), {
  activeTab: undefined
});

const emit = defineEmits(['navigateContent']);

const { isMobile } = useCurrentWindowSize();

const activeTab = ref(props.activeTab);

const setActiveTab = (tabId: string) => {
  activeTab.value = tabId;
};

const handleScroll = (event: Event, tabId: string) => {
  event.preventDefault();

  setActiveTab(tabId);
  useScrollToContainer(tabId, undefined, 'start', isMobile ? offsets.mobile : offsets.desktop);

  emit('navigateContent', tabId);
};

const tabEvent = (label: string) => {
  gtmEvent('menu_inpage', {
    menu_label: label,
    pagename: 'restaurantsandbars::restaurantdetails'
  });
};

const mappedTabs = computed(() =>
  props.tabs.map((tab, index) => ({
    ...tab,
    isActive: activeTab.value ? tab.id === activeTab.value : index === 0
  }))
);

onMounted(() => {
  window.addEventListener('scroll', () => {
    const activeElement = detectElements(props.tabs.map((tab) => tab.id));
    if (activeElement) {
      setActiveTab(activeElement);
    }
  });
});
</script>

<style lang="scss" scoped>
@use 'Tabs.scss';
</style>
