<template>
  <div class="Restaurant-map Grid-twelve Grid-twelve--restaurant">
    <ux-molecules-map :center="center" :markers="markers" :variant="MapVariant.RESTAURANT" />
    <div class="Restaurant-map__details">
      <div class="Restaurant-map__location">
        <ux-atoms-typo :text="$t('ux.molecules.restaurantMap.address')" as="h3" variant="text-heading-01" />
        <ux-atoms-typo :text="hotelName" as="p" v-if="hotelName" />
        <ux-atoms-typo :text="street" as="p" v-if="street" />
        <ux-atoms-typo :text="address" as="p" v-if="city && zipCode" />
        <ux-atoms-typo as="p">
          <ux-atoms-link :href="itinerary" class="Restaurant-map__itinerary mt-2" target="_blank" underline v-if="center?.lat && center?.lng">
            {{ $t('ux.molecules.restaurantMap.itinerary') }}
            <ux-atoms-icon name="external" />
          </ux-atoms-link>
        </ux-atoms-typo>
      </div>
      <div class="Restaurant-map__booking" v-if="(phonePrefix && phone) || email">
        <ux-atoms-typo :text="$t('ux.molecules.restaurantMap.booking')" as="h3" class="mt-3" variant="text-heading-01" />
        <ux-atoms-typo as="p">
          <ux-atoms-link :href="phoneTo" @click="gtmPhoneEvent" target="_blank" underline v-if="phonePrefix && phone">
            {{ phoneNumber }}
          </ux-atoms-link>
        </ux-atoms-typo>
        <ux-atoms-typo as="p">
          <ux-atoms-link :href="mailTo" @click="gtmEmailEvent" target="_blank" underline v-if="email">
            {{ email }}
          </ux-atoms-link>
        </ux-atoms-typo>
      </div>
      <ul class="Restaurant-map__socials mt-2" v-if="facebook || instagram">
        <li class="Restaurant-map__social" v-if="facebook">
          <ux-atoms-link :href="facebook" target="_blank" underline>
            <ux-atoms-social name="facebook" />
          </ux-atoms-link>
        </li>
        <li class="Restaurant-map__social" v-if="instagram">
          <ux-atoms-link :href="instagram" target="_blank">
            <ux-atoms-social name="instagram" />
          </ux-atoms-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { MapVariant, type coordType, type markerType } from '~/components/ux/molecules/Map/types';
import { gtmEvent } from '~/helpers/gtm';

export interface RestaurantMapProps {
  center: coordType;
  city?: string;
  email?: string;
  facebook?: string;
  hotelName?: string;
  instagram?: string;
  markers?: markerType[];
  phone?: string;
  phonePrefix?: string;
  socials?: { link: string; name: string }[];
  street?: string;
  zipCode?: string;
}

const props = defineProps<RestaurantMapProps>();

const address = computed(() => `${props.city}, ${props.zipCode}`);
const phoneNumber = computed(() => `(+${props.phonePrefix}) ${props.phone}`);
const phoneTo = computed(() => `tel:+${props.phonePrefix}${props.phone}`);
const mailTo = computed(() => `mailto:${props.email}`);
const itinerary = computed(() => `https://www.google.com/maps?saddr=Current+Location&daddr=${props.center.lat},${props.center.lng}`);

const gtmPhoneEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'phone call',
    bloc_name: 'contact',
    pagename: 'restaurantsandbars::restaurantdetails'
  });
};

const gtmEmailEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'email',
    bloc_name: 'contact',
    pagename: 'restaurantsandbars::restaurantdetails'
  });
};
</script>
<style lang="scss" scoped>
@use 'RestaurantMap.scss';
</style>
