<template>
  <ul class="Time-selector" v-if="store.displayTimes">
    <li :key="time.slot" class="Time-selector__time" v-for="time in times">
      <input
        :checked="inputIsChecked(time.slot)"
        :id="`time-${time.slot}`"
        :value="time.slot"
        @click="$emit('time::select', time)"
        class="Time-selector__time-input"
        name="time"
        required
        type="radio"
      />
      <label :for="`time-${time.slot}`" class="Time-selector__time-label">
        <ux-atoms-typo as="span" class="Time-selector__time-text" color="grey-1" variant="text-regular">
          <time>{{ time.slot }}</time>
        </ux-atoms-typo>
      </label>
      <ux-atoms-tag class="Time-selector__tag" v-if="time?.offers?.length" variant="offer" />
    </li>
  </ul>
</template>
<script lang="ts" setup>
import { bookingObject } from '~/components/ux/molecules/BookingFunnel/types';
import { AvailabilitySlot } from '~/graphql';

export interface TimeSelectorProps {
  date?: string;
  preSelectedBooking?: bookingObject;
  times?: AvailabilitySlot[] | null;
}

const props = withDefaults(defineProps<TimeSelectorProps>(), {
  date: undefined,
  preSelectedBooking: undefined,
  times: undefined
});

const store = useBookRestaurantStore();

const emitTime = 'time::select';
// eslint-disable-next-line vue/valid-define-emits
defineEmits([emitTime]);

const inputIsChecked = (slot: string) => {
  return slot === props.preSelectedBooking?.time && props.date === props.preSelectedBooking?.date;
};
</script>
<style lang="scss" scoped>
@use 'TimeSelector.scss';
</style>
