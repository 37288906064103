<template>
  <div class="Restaurant-meals-type">
    <ux-atoms-image :media="mediaImage" class="Restaurant-meals-type__image" />
    <div class="Restaurant-meals-type__content">
      <ux-atoms-typo
        :text="$t('ux.molecules.restaurantMealsType.menuType')"
        as="p"
        class="Restaurant-meals-type__sub-title"
        color="black"
        v-if="props.accommodateMeals?.length"
        variant="text-medium"
      />
      <ul class="Restaurant-meals-type__list" v-if="props.accommodateMeals?.length">
        <li :key="meal" class="Restaurant-meals-type__list-item" v-for="meal in props.accommodateMeals">
          <ux-atoms-typo :text="meal" as="span" color="black" variant="text-small" />
        </li>
      </ul>
      <div class="Restaurant-meals-type__button">
        <ux-molecules-restaurant-menu :external-menu-url="externalMenuUrl" :menu-type="menuType" :menus="menus" display-icon variant="primary" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { MenuType, RestaurantMedia, RestaurantMenu } from '~/graphql';

export interface RestaurantMealsType {
  accommodateMeals: string[] | undefined;
  externalMenuUrl?: null | string;
  medias: RestaurantMedia[] | undefined;
  menuType?: MenuType | null;
  menus?: RestaurantMenu[] | null;
}

const props = withDefaults(defineProps<RestaurantMealsType>(), {
  accommodateMeals: undefined,
  externalMenuUrl: undefined,
  medias: undefined,
  menuType: undefined,
  menus: undefined
});

const mediaImage = computed(() => {
  const images = props.medias?.filter((media) => !!media) || [];
  return images[2] || images[1] || images[0] || null;
});
</script>
<style lang="scss" scoped>
@use 'RestaurantMealsType.scss';
</style>
