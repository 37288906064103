<template>
  <div :class="['Restaurant-actions', { 'Restaurant-actions--isNav': isNav }]">
    <ux-atoms-button
      :external-menu-url="externalMenuUrl"
      :full="!isNav"
      :label="$t('ux.molecules.restaurantActions.bookATable')"
      :size="isNav ? 's' : 'm'"
      @click="goToBooking"
      v-if="hasPartners"
    />
    <ux-molecules-restaurant-menu :external-menu-url="externalMenuUrl" :is-nav="isNav" :menu-type="menuType" :menus="menus" />
    <Teleport to="#teleports">
      <ux-molecules-modal
        :event-element="bookRestaurantStore.modalTarget"
        @close-modal="cleanBookQuery"
        button-no-border
        button-position="right"
        has-label-id
        id="Booking-funnel-id"
        no-y-space
        size="medium"
        v-if="bookRestaurantStore.xxsModalIsOpen && !isNav"
        v-model="bookRestaurantStore.xxsModalIsOpen"
      >
        <template #header>
          <ux-atoms-typo
            :text="$t('ux.molecules.restaurantActions.bookATable')"
            as="p"
            class="Restaurant-actions__modal-header"
            color="dark"
            v-if="!bookingWidgetUrl && !partnerId"
            variant="expressive-heading-05"
          />
        </template>
        <iframe :src="partnerBookingUrl" class="Restaurant-actions__modal-iframe" frameborder="0" v-if="hasMatchingWidget"></iframe>
        <ux-molecules-booking-funnel
          :currency-symbol="currencySymbol"
          :offers="offers"
          :terms-and-conditions="termsAndConditions"
          @component-ready="bookingFunnelReady = true"
          v-else
        />
        <div class="Restaurant-actions__loader" v-if="(!bookingWidgetUrl || !partnerId) && !bookingFunnelReady">
          <ux-atoms-loader aria-label="Loading" />
        </div>
      </ux-molecules-modal>
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import { MenuType, RestaurantMenu, RestaurantOffers } from '~/graphql';
import { PARTNER_ID } from '~/helpers/constants';
import { newUTCToday } from '~/helpers/date';
import { gtmEvent } from '~/helpers/gtm';
import { useBookRestaurantStore } from '~/stores/bookRestaurantStore';

export interface RestaurantActionsProps {
  bookingType?: 'CMS' | 'XSS' | undefined;
  bookingWidgetUrl?: null | string;
  currencySymbol?: null | string;
  externalMenuUrl?: null | string;
  hasMatchingWidget?: boolean;
  hasPartners?: boolean;
  isNav?: boolean;
  menuType?: MenuType | null;
  menus?: RestaurantMenu[] | null;
  offers?: RestaurantOffers[];
  partnerId?: string;
  termsAndConditions?: string;
}

const props = withDefaults(defineProps<RestaurantActionsProps>(), {
  bookingType: 'XSS',
  bookingWidgetUrl: undefined,
  currencySymbol: undefined,
  externalMenuUrl: undefined,
  hasMatchingWidget: false,
  hasPartners: undefined,
  isNav: false,
  menuType: undefined,
  menus: undefined,
  offers: undefined,
  partnerId: undefined,
  termsAndConditions: undefined
});

const bookingFunnelReady = ref(false);

const bookRestaurantStore = useBookRestaurantStore();
const { searchParams } = useSearchRestaurant();
const route = useRoute();
const router = useRouter();

const cleanBookQuery = () => {
  if (route.query?.book && route.query.book === 'true') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { book, ...newQueryParams } = route.query;

    router.push({
      query: newQueryParams
    });
  }
};

const goToBooking = (event: Event) => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'book a table',
    bloc_name: 'main bloc',
    pagename: 'restaurantsandbars::restaurantdetails'
  });

  const element = event.target as HTMLElement;

  router.push({
    query: {
      book: 'true',
      date: searchParams.value.date,
      guests: searchParams.value.groupSize ?? 2
    }
  });

  return bookRestaurantStore.toggleXXSModal(element);
};

const partnerBookingUrl = computed(() => {
  if (props.partnerId === PARTNER_ID.ZENCHEF) {
    return props.bookingWidgetUrl + `&pax=${searchParams.value?.groupSize}` + (route.query?.date ? `&day=${searchParams.value.date}` : '');
  } else if (props.partnerId === PARTNER_ID.THEFORK) {
    return (
      props.bookingWidgetUrl +
      `?pax=${searchParams.value?.groupSize}` +
      (route.query?.date ? `&date=${searchParams.value.date}` : '') +
      (searchParams.value?.groupSize && route.query?.date ? '&step=hour' : '')
    );
  } else if (props.partnerId === PARTNER_ID.OPENTABLE) {
    const formatedDate = `${searchParams.value.date ?? newUTCToday}T12%3A00`;
    return props.bookingWidgetUrl + `&partySize=${searchParams.value?.groupSize}&dateTime=${formatedDate}`;
  }
});
</script>
<style lang="scss" scoped>
@use 'RestaurantActions.scss';
</style>
