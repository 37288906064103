<template>
  <div
    :class="[
      'Text-area',
      {
        'Text-area--error': error
      }
    ]"
  >
    <ux-atoms-typo :class="['Text-area-label', { 'accessibility-sr-only': label }]" :for="id" as="label" v-if="label">
      {{ label }}
    </ux-atoms-typo>
    <div class="Text-area__container">
      <textarea
        :aria-describedby="helperText ? `${name}-helper-text` : undefined"
        :aria-invalid="error"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        class="Text-area__container-text"
        v-model="value"
      />
    </div>
    <ux-atoms-typo :id="`Text-area-${name}-error-text`" as="p" class="Text-area__error" v-if="error">
      {{ errorText }}
    </ux-atoms-typo>
  </div>
</template>

<script setup lang="ts">
// Taken from wellness_apps/packages/front/src/components/ux/atoms/WlnsForm/WlnsTextArea/WlnsTextArea.vue

export interface WlnsTextAreaProps {
  clickable?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  id: string;
  label: string;
  modelValue?: string;
  name: string;
  placeholder?: string;
}

const props = withDefaults(defineProps<WlnsTextAreaProps>(), {
  accessoryLeft: undefined,
  clickable: true,
  error: false,
  errorText: undefined,
  helperText: undefined,
  modelValue: undefined,
  placeholder: undefined,
  type: 'text'
});

const emit = defineEmits(['update:modelValue']);

const value = useVModel(props, 'modelValue');

watch(value, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped lang="scss">
@use 'TextArea.scss';
</style>
