<template>
  <div @keydown.enter="model = value" class="radio-input">
    <input
      :aria-describedby="description ? `${description}-helper-text` : undefined"
      :checked="model === value"
      :disabled="disabled"
      :id="id"
      :name="name"
      :value="value"
      class="radio-input__input"
      type="radio"
      v-model="model"
    />
    <ux-atoms-typo :for="id" as="label" class="radio-input__label" v-if="label">
      {{ label }}
    </ux-atoms-typo>
  </div>
</template>

<script setup lang="ts">
export interface WlnsRadioInputProps {
  description?: string;
  disabled?: boolean;
  id: string;
  label?: string;
  modelValue: boolean | null | string;
  name: string;
  value: boolean | string;
}

const props = withDefaults(defineProps<WlnsRadioInputProps>(), {
  description: undefined,
  label: undefined
});
const model = useVModel(props, 'modelValue');
</script>
<style scoped lang="scss">
@use 'RadioInput.scss';
</style>
