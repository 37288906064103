import { Booking, useBookTableMutation, useBookingPaiementMutation } from '~/graphql';

export function useBooking() {
  const { mutate: paiementMutate, onDone: paiementOnDone } = useBookingPaiementMutation();
  const { mutate, onDone } = useBookTableMutation();

  const bookingPaiement = (
    restaurantId: string,
    bookingReference: string,
    groupeSize: number,
    bookingDate: string,
    bookingTime: string,
    paymentMethodId: string,
    guestFirstname: string,
    guestLastname: string,
    guestEmail: string,
    guestMobile: string,
    zipCode: string,
    promotionId?: null | string,
    paymentIntentId?: null | string
  ): Promise<Booking> => {
    return new Promise((resolve, reject) => {
      paiementMutate({
        bookingDate,
        bookingReference,
        bookingTime,
        groupSize: groupeSize,
        guest: {
          email: guestEmail,
          firstname: guestFirstname,
          lastname: guestLastname,
          mobile: guestMobile,
          postCode: zipCode
        },
        payment: {
          paymentIntentId,
          paymentMethodId
        },
        promotionId,
        restaurantId
      });

      paiementOnDone((param) => {
        if (param.errors || !param.data || !param.data.bookingPaiement) {
          reject(param.errors);
        } else {
          resolve(param.data?.bookingPaiement);
        }
      });
    });
  };

  const bookTable = (
    restaurantId: string,
    groupeSize: number,
    bookingDate: string,
    bookingTime: string,
    guestFirstname: string,
    guestLastname: string,
    guestEmail: string,
    guestMobile: string,
    promotionId?: null | string,
    comment?: null | string
  ): Promise<Booking | null> => {
    return new Promise((resolve, reject) => {
      mutate({
        bookingDate,
        bookingTime,
        comment,
        groupSize: groupeSize,
        guest: {
          email: guestEmail,
          firstname: guestFirstname,
          lastname: guestLastname,
          mobile: guestMobile
        },
        promotionId,
        restaurantId
      });

      onDone((param) => {
        if (param.errors || !param.data || !param.data.bookTable) {
          reject(param.errors);
        } else {
          resolve(param.data.bookTable);
        }
      });
    });
  };

  return {
    bookTable,
    bookingPaiement
  };
}
