import { ref } from 'vue';

import type { ScheduleOpenedDay } from '~/graphql/schema';

import { daysOfWeek } from '~/core/constants';

interface NextOpening {
  dayIndex: number;
  dayLabel: string;
  from?: string;
  to?: string;
}
export function useRestaurantNextOpening(schedule: ScheduleOpenedDay[], currentDayIndex: number, currentTime: string) {
  const nextOpening = ref<NextOpening | null>(null);

  if (!schedule || schedule.length === 0) return null;

  // Loop through the next 7 days (including today)
  for (let i = 0; i < 7; i++) {
    const dayIndex = (currentDayIndex + i) % 7;
    const daySchedule = schedule.find((day) => day.day === daysOfWeek[dayIndex]);

    if (daySchedule?.openingHours?.length) {
      if (i !== 0) {
        // Future days
        nextOpening.value = { dayIndex, dayLabel: daySchedule.day, from: daySchedule.openingHours[0]?.from };
        return nextOpening;
      }
      // Today
      const currentlyOpen = daySchedule.openingHours.find((opening) => opening && currentTime >= opening.from && currentTime <= opening.to);
      if (currentlyOpen) {
        nextOpening.value = { dayIndex, dayLabel: daySchedule.day, to: currentlyOpen.to };
        return nextOpening;
      }
      const opensToday = daySchedule.openingHours.find((opening) => opening && currentTime < opening.from);
      if (opensToday) {
        nextOpening.value = { dayIndex, dayLabel: daySchedule.day, from: opensToday.from };
        return nextOpening;
      }
    }
  }

  return nextOpening;
}
