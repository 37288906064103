<template>
  <div :class="['Restaurant-availabilities-content', { 'Restaurant-availabilities-content--without-actions': !displayRestaurantActions }]">
    <ux-atoms-typo :text="title" as="h2" class="Restaurant-availabilities-content__title" />

    <div
      :aria-busy="initialAvailabilities?.loading"
      aria-live="polite"
      class="Restaurant-availabilities-content__skeleton"
      v-if="initialAvailabilities?.loading"
    >
      <span class="accessibility-sr-only">{{ $t('ux.molecules.restaurantAvailabilities.loading') }}</span>
      <ux-molecules-restaurant-availabilities-content-skeleton />
    </div>
    <div class="Restaurant-availabilities-content__fullsizer" v-else-if="hasPartners && availabilities?.length && hasCurrentDateAvailability">
      <ul class="Restaurant-availabilities-content__list">
        <li :key="availability?.slot" v-for="availability in availabilities">
          <ux-atoms-chip-text
            :aria-label="$t('ux.molecules.restaurantAvailabilities.bookATableAt', { time: availability?.slot })"
            :class="[
              'Restaurant-availabilities-content__button',
              { 'Restaurant-availabilities-content__button--offer': availability?.offers?.length }
            ]"
            :key="JSON.stringify(availability)"
            @click="openBookingModal($event, { time: availability?.slot as string, date: newUTCDate(availability?.date), people: guests })"
            as="button"
          >
            <time tabindex="-1">{{ availability?.slot }}</time>
            <span class="Restaurant-availabilities-content__icon-container" v-if="availability?.offers?.length">
              <ux-atoms-icon :description="$t('ux.molecules.restaurantAvailabilities.hasOffer')" name="offer" />
            </span>
          </ux-atoms-chip-text>
        </li>
        <li v-if="moreAvailabilities">
          <ux-atoms-chip-text :aria-label="$t('ux.molecules.restaurantAvailabilities.viewMore')" @click="openBookingModal" as="button">
            <ux-atoms-icon class="Restaurant-availabilities-content__icon" name="menu-dots" size="m" />
          </ux-atoms-chip-text>
        </li>
      </ul>
    </div>
    <div class="Restaurant-availabilities__unavailable" v-else-if="hasPartners">
      <ux-atoms-tag :label="$t('ux.atoms.tag.noAvailability')" class="Restaurant-availabilities-next-availability__tag" variant="no-availability" />
      <span aria-hidden="true">•</span>
      <ux-atoms-button @click="searchNextAvailability" aria-hidden class="Restaurant-availabilities-next-availability__button" variant="link">
        {{ $t('pages.restaurant.nextAvailabilities') }}
      </ux-atoms-button>
    </div>
    <ux-atoms-chip-text as="div" class="Restaurant-availabilities__chip" v-else>
      <span>{{ $t('ux.molecules.restaurantAvailabilities.phoneNumberLabel') }}</span>
      <a :href="`tel:${phoneNumber}`" @click="gtmPhoneEvent" class="Restaurant-availabilities-content__link">{{ phoneNumber }}</a>
    </ux-atoms-chip-text>
  </div>
</template>
<script lang="ts" setup>
import { BOOKING_FUNNEL_DEFAULT } from '~/core/constants';
import { RestaurantIdentifier, SingleAvailabilitiesQuery } from '~/graphql';
import { newUTCDate } from '~/helpers/date';
import { gtmEvent } from '~/helpers/gtm';
import { useBookRestaurantStore } from '~/stores/bookRestaurantStore';

import type { bookingObject } from '../BookingFunnel/types';

const { t } = useI18n();

const { nextAvaibilityQuery, searchParams } = useSearchRestaurant();
const route = useRoute();
const router = useRouter();

export interface RestaurantAvailabilitiesContentProps {
  displayRestaurantActions?: boolean | null;
  hasPartners?: boolean | null;
  identifiers?: RestaurantIdentifier[] | null;
  initialAvailabilities?: {
    data: SingleAvailabilitiesQuery['singleAvailabilities'] | null;
    loading: boolean;
  };
  phoneNumber?: null | string;
}

const props = defineProps<RestaurantAvailabilitiesContentProps>();

const store = useBookRestaurantStore();

defineEmits(['restaurantAvailabilities::openModal']);

const restaurantAvailabilities = computed(() =>
  props.initialAvailabilities?.data?.filter((availability) => availability?.date === searchParams.value.date)
);

const guests = computed(() => {
  return route.query.guests ? parseInt(route.query.guests as string) : BOOKING_FUNNEL_DEFAULT;
});

const availabilities = computed(() => {
  return restaurantAvailabilities?.value?.slice(0, 4);
});

const hasCurrentDateAvailability = computed(() => {
  return (
    restaurantAvailabilities.value &&
    restaurantAvailabilities?.value?.length > 0 &&
    restaurantAvailabilities.value?.[0]?.date === searchParams.value.date
  );
});

const moreAvailabilities = computed(() => {
  return restaurantAvailabilities.value && restaurantAvailabilities?.value?.length >= 4;
});

const title = computed(() => {
  if (props.hasPartners) {
    const dateParams = new Date(searchParams.value.date);

    return t('ux.molecules.restaurantAvailabilities.title', { day: useDateFormat(dateParams, 'EEE dd MMMM') });
  }
  return t('ux.molecules.restaurantAvailabilities.contactTitle');
});

const searchNextAvailability = async (e?: Event) => {
  if (!route.params.restaurantCode || Array.isArray(route.params.restaurantCode)) {
    return;
  }

  store.toggleXXSModal(e?.target as HTMLElement, undefined, undefined, true, true);

  if (searchParams.value.groupSize > 6) {
    store.toggleXXSModal(e?.target as HTMLElement, undefined, undefined, false, true);

    await router.push({
      query: {
        book: 'true',
        date: searchParams.value.date,
        guests: searchParams.value.groupSize
      }
    });

    return;
  }

  const response = await nextAvaibilityQuery({
    from: searchParams.value.date,
    groupSize: searchParams.value.groupSize,
    restaurantId: route.params.restaurantCode
  });

  if (response?.nextAvailability) {
    store.toggleXXSModal(
      e?.target as HTMLElement,
      {
        date: response?.nextAvailability.date,
        people: response?.nextAvailability.groupSize ?? 2,
        time: response?.nextAvailability.slot
      },
      undefined,
      undefined,
      true
    );

    await router.push({
      query: {
        book: 'true',
        date: response?.nextAvailability.date,
        guests: response?.nextAvailability.groupSize ?? 2,
        slot: response?.nextAvailability.slot
      }
    });
  } else {
    await router.push({
      query: {
        book: 'true',
        date: searchParams.value.date,
        guests: searchParams.value.groupSize
      }
    });
  }
};

const openBookingModal = (e: Event, bookingSlot: bookingObject) => {
  const el = e.target;

  router.push({
    query: {
      book: 'true',
      date: bookingSlot?.date ?? searchParams.value.date,
      guests: bookingSlot?.people ?? searchParams.value.groupSize,
      slot: bookingSlot?.time
    }
  });

  store.toggleXXSModal(el as HTMLElement, bookingSlot);
};

const gtmPhoneEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'phone call',
    bloc_name: 'contact',
    pagename: 'restaurantsandbars::restaurantdetails'
  });
};

onMounted(async () => {
  const query = route.query.nextAvailability as string;
  if (query === 'true') {
    await searchNextAvailability();
  }
});
</script>
<style lang="scss" scoped>
@use 'RestaurantAvailabilitiesContent.scss';
</style>
