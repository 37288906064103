<template>
  <div class="Restaurant-offers">
    <ul class="Restaurant-offers__list">
      <li :key="`restaurant-offer-${offer.id}`" class="Restaurant-offers__offer" v-for="offer in offersOrderedByAP">
        <ux-molecules-restaurant-offer
          :description="cutText(offer?.description)"
          :id="offer.id as string"
          :is-accor-plus="offer.isAccorPlus as boolean"
          :src="computedSrc(offer.images) as string"
          :title="offer.name"
          @restaurant-offer::open-modal="toggleModal"
          v-if="offer?.name"
        />
      </li>
    </ul>

    <Teleport to="#teleports">
      <ux-molecules-modal
        :event-element="eventElement"
        :key="`restaurant-offer-${selectedOffer?.id}`"
        button-no-border
        button-position="right"
        has-label-id
        size="medium"
        v-if="selectedOffer"
        v-model="modalIsOpen"
      >
        <template #header>
          <ux-atoms-typo
            :text="selectedOffer?.name"
            as="p"
            class="Restaurant-offers__offer-title"
            color="grey-1"
            text-align="center"
            variant="expressive-heading-05"
          />
        </template>

        <ux-molecules-restaurant-offers-modal-content
          :currency-symbol="currencySymbol"
          :description="selectedOffer?.description as string"
          :max="selectedOffer?.maxGuests as number"
          :min="selectedOffer?.minGuests as number"
          :price-details="selectedOffer?.price as number"
          :restaurant-timezone="restaurantTimezone"
          :src="computedSrc(selectedOffer?.images) as string"
          :title="selectedOffer?.description as string"
          :validity-details="selectedOffer?.validityPeriods as RestaurantOffersValidity[]"
          @restaurant-offers-modal-content::book-offer="bookOffer"
          v-if="selectedOffer"
        />
      </ux-molecules-modal>
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { RestaurantOffers, RestaurantOffersImage, RestaurantOffersValidity } from '~/graphql';
import { BOOKING_FUNNEL_DEFAULT } from '~/helpers/constants';

export interface RestaurantOffersProps {
  currencySymbol?: string;
  offers?: RestaurantOffers[];
  restaurantId?: string;
  restaurantTimezone?: string;
}

const props = withDefaults(defineProps<RestaurantOffersProps>(), {
  currencySymbol: undefined,
  offers: undefined,
  restaurantId: undefined,
  restaurantTimezone: undefined
});

const userStore = useUserStore();

const route = useRoute();
const router = useRouter();

const { nextAvaibilityQuery, searchParams } = useSearchRestaurant();

const { isMobile } = useCurrentWindowSize();

const offersOrderedByAP = computed(() => {
  if (!props.offers) {
    return [];
  }

  if (!userStore.APZone) {
    return props.offers;
  }

  return [...props.offers].sort((a, b) => {
    if (a.isAccorPlus && !b.isAccorPlus) {
      return -1;
    }
    if (!a.isAccorPlus && b.isAccorPlus) {
      return 1;
    }
    return 0;
  });
});

const eventElement = ref<HTMLElement>();
const modalIsOpen = ref<boolean>(false);
const currentOfferId = ref<string>();

const cutText = (text?: null | string) => {
  if (!text) {
    return '';
  }
  return text.length <= 150 ? text : text.slice(0, 150) + '...';
};

const toggleModal = (e: Event, title: string) => {
  eventElement.value = e.target as HTMLElement;
  currentOfferId.value = title;
  modalIsOpen.value = !modalIsOpen.value;
};

const selectedOffer = computed(() => {
  return props.offers?.find((offer) => offer.id === currentOfferId.value);
});

const computedSrc = (images?: RestaurantOffersImage | null) => {
  if (!images) {
    return null;
  }

  if (isMobile.value) {
    return images?.mobileImage || images?.desktopImage;
  }

  return images?.desktopImage || images?.mobileImage;
};

const bookRestaurantStore = useBookRestaurantStore();

const bookOffer = async (event: Event) => {
  if (!route.params.restaurantCode || Array.isArray(route.params.restaurantCode)) {
    return;
  }

  modalIsOpen.value = false;

  bookRestaurantStore.toggleXXSModal(event?.target as HTMLElement, undefined, selectedOffer.value, true, true);

  await router.push({
    query: {
      book: 'true',
      guests: searchParams.value.groupSize,
      nextAvailabilityQuery: 'true'
    }
  });

  if (selectedOffer.value?.isAccorPlus && (!userStore.APZone || (userStore.APZone && !userStore.isAPUser))) {
    bookRestaurantStore.toggleXXSModal(event?.target as HTMLElement, undefined, selectedOffer.value, true, true);

    await router.push({
      query: {
        book: 'true',
        date: searchParams.value.date,
        guests: searchParams.value.groupSize
      }
    });

    return;
  }

  const payload = {
    groupSize: searchParams.value.groupSize ?? BOOKING_FUNNEL_DEFAULT,
    maxGuests: selectedOffer.value?.maxGuests as number,
    minGuests: selectedOffer.value?.minGuests as number,
    offerId: selectedOffer.value?.id as string,
    restaurantId: props.restaurantId as string,
    validityPeriods: selectedOffer.value?.validityPeriods
      ?.filter((period) => !!period)
      .map((period) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...cleanPeriod } = period;
        return cleanPeriod;
      })
  };

  const response = await nextAvaibilityQuery(payload);

  const displayWarning = !response || !response.nextAvailability?.offers?.some((offer) => offer?.id === Number(selectedOffer.value?.id));
  const booking = {
    date: response?.nextAvailability?.date as string,
    people: response?.nextAvailability?.groupSize as number,
    time: response?.nextAvailability?.slot as string
  };

  bookRestaurantStore.toggleXXSModal(event?.target as HTMLElement, booking, selectedOffer.value, displayWarning, true);

  if (displayWarning) {
    await router.push({
      query: {
        book: 'true',
        date: searchParams.value.date,
        guests: searchParams.value.groupSize,
        nextAvailabilityQuery: 'true'
      }
    });
  } else {
    await router.push({
      query: {
        book: 'true',
        date: response?.nextAvailability?.date,
        guests: response?.nextAvailability?.groupSize,
        slot: response?.nextAvailability?.slot
      }
    });
  }
};
</script>
<style lang="scss" scoped>
@use 'RestaurantOffers.scss';
</style>
