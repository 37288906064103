<template>
  <div class="Booking-summary">
    <div class="Booking-summary__detail" v-if="displayDetails">
      <ux-atoms-typo as="p" color="light" variant="text-regular">
        <template v-if="guests">{{ $t('ux.molecules.bookingSummary.guests', { n: guests }) }}</template>
        <template v-if="guests && (date || time)">&nbsp;• &nbsp;</template>
        <template v-if="formattedDate">{{ formattedDate }}</template>
        <template v-if="date && time">&nbsp;•&nbsp;</template>
        <template v-if="time">{{ time }}</template>
      </ux-atoms-typo>
      <ux-atoms-button
        :label="$t('ux.molecules.bookingSummary.edit')"
        @button::click="$emit('bookingSummary::goBack')"
        class="Booking-summary__detail-link"
        v-if="step && step > 1"
        variant="link"
      />
    </div>
    <ux-atoms-typo
      :text="$t('ux.molecules.bookingSummary.error')"
      as="p"
      class="Booking-summary__error"
      id="Booking-summary-error-text"
      v-if="bookingError"
    />
    <ux-atoms-button :disabled="isDisabled || isLoading" :is-loading="isLoading" @button::click="$emit('bookingSummary::confirm')" variant="tertiary">
      {{ $t('ux.molecules.bookingSummary.confirm') }}
    </ux-atoms-button>
  </div>
</template>
<script setup lang="ts">
import { format } from 'date-fns';

import { formatToUTC } from '~/helpers/date';

export interface BookingSummaryProps {
  bookingError?: boolean;
  date?: string | undefined;
  guests?: number | undefined;
  isDisabled?: boolean;
  isLoading?: boolean;
  step?: number;
  time?: string | undefined;
}

const props = withDefaults(defineProps<BookingSummaryProps>(), {
  bookingError: false,
  date: undefined,
  guests: undefined,
  isDisabled: undefined,
  isLoading: false,
  step: undefined,
  time: undefined
});

defineEmits(['bookingSummary::goBack', 'bookingSummary::confirm']);

const formattedDate = computed(() => {
  return format(formatToUTC(props.date as string), 'dd/MM/y');
});

const displayDetails = computed(() => {
  return props.step && props.step !== 3;
});
</script>
<style lang="scss" scoped>
@use 'BookingSummary.scss';
</style>
