<template>
  <div :class="['Booking-offer', { 'Booking-offer--disabled': disabled }]">
    <input :checked="isChecked" :disabled="disabled" :id="id" @change.prevent="handleCheck" class="Booking-offer__input" type="checkbox" />
    <div class="Booking-offer__checkbox">
      <ux-atoms-icon class="Booking-offer__checkbox-icon" name="checkbox" size="xs" />
    </div>
    <div :class="['Booking-offer__details', { 'Booking-offer__details--full': detailsOpen }]">
      <div :class="['Booking-offer__detail', { 'Booking-offer__detail--disabled': disabled }]">
        <ux-atoms-typo
          :class="['Booking-offer__detail-title', { 'Booking-offer__detail-title--disabled': disabled }]"
          :for="id"
          :text="name"
          as="label"
          color="dark"
          variant="text-heading-02"
        />
        <ux-atoms-typo
          :class="['Booking-offer__detail-ap-offer', { 'Booking-offer__detail-ap-offer--disabled': disabled }]"
          :text="$t('ux.molecules.bookingFunnel.accorPlusOffer.title')"
          as="label"
          color="dark"
          v-if="isAccorPlus"
          variant="text-heading-02"
        />
        <ux-atoms-typo :text="$t('ux.molecules.bookingOffer.mandatoryLabel')" as="p" v-if="!isStandard" variant="text-regular" />
        <ux-atoms-typo :text="$t('ux.molecules.bookingOffer.depositRequired')" as="p" v-if="isDepositRequired" variant="text-regular" />
        <ux-atoms-typo :text="$t('ux.molecules.bookingOffer.creditCardRequired')" as="p" v-if="isCreditCardRequired" variant="text-regular" />
      </div>
      <div :class="['Booking-offer__detail-content', { 'Booking-offer__detail-content--disabled': disabled }]">
        <ux-atoms-typo :text="computedDescription" as="p" class="Booking-offer__detail-description" variant="text-regular" />
        <div v-if="!isToggleable || detailsOpen">
          <ux-molecules-booking-offer-booking-offer-detail :details="priceLabel" :text="$t('ux.molecules.bookingOffer.price')" v-if="priceLabel" />
          <ux-molecules-booking-offer-booking-offer-detail
            :details="validityDetailsLabel"
            :text="$t('ux.molecules.bookingOffer.validity')"
            v-if="validityDetailsLabel?.length"
          />
          <ux-molecules-booking-offer-booking-offer-detail :details="guestsLabel" :text="$t('ux.molecules.bookingOffer.guests')" v-if="guestsLabel" />
        </div>
      </div>
    </div>
    <ux-atoms-button
      :label="detailsOpen ? $t('ux.molecules.bookingOffer.buttonHide') : $t('ux.molecules.bookingOffer.buttonSee')"
      @click="toggleDetails"
      class="Booking-offer__toggler"
      v-if="isToggleable"
      variant="link"
    />
  </div>
</template>
<script lang="ts" setup>
import { RestaurantOffersValidity } from '~/graphql';

export interface BookingOfferProps {
  currencySymbol?: null | string;
  description?: string;
  disabled?: boolean;
  guestsDetails?: { max: number; min: number };
  id?: string;
  isAccorPlus?: boolean;
  isCreditCardRequired: boolean;
  isDepositRequired: boolean;
  isMandatory?: boolean;
  isStandard?: boolean;
  name: string;
  priceDetails?: number;
  restaurantTimezone?: string;
  selectedOfferId: null | string;
  validityDetails?: RestaurantOffersValidity[];
}

const props = withDefaults(defineProps<BookingOfferProps>(), {
  currencySymbol: undefined,
  description: undefined,
  disabled: false,
  guestsDetails: undefined,
  id: undefined,
  isCreditCardRequired: false,
  isDepositRequired: false,
  isMandatory: false,
  isStandard: true,
  name: undefined,
  priceDetails: undefined,
  restaurantTimezone: undefined,
  selectedOfferId: undefined,
  validityDetails: undefined
});

const { guestsLabel, priceLabel, validityDetailsLabel } = useOffer(
  props.validityDetails,
  props.priceDetails,
  props.currencySymbol,
  props.restaurantTimezone,
  props.guestsDetails
);

const emit = defineEmits(['update:selectedOffer']);

const detailsOpen = ref<boolean>(false);

const isToggleable = ref<boolean>(true);

const computedDescription = computed(() =>
  props.description && props.description.length > 150 && !detailsOpen.value ? props.description?.slice(0, 150) + '...' : props.description
);

const toggleDetails = () => {
  detailsOpen.value = !detailsOpen.value;
};

const isChecked = computed(() => props.selectedOfferId === props.id);

const handleCheck = (event: Event) => {
  const target = event.target as HTMLInputElement;

  if (props.selectedOfferId === props.id && !props.isStandard && target) {
    target.checked = true;
    return;
  }

  emit('update:selectedOffer', props.selectedOfferId === props.id ? null : props.id);
};
</script>
<style lang="scss" scoped>
@use 'BookingOffer.scss';
</style>
