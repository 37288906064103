<template>
  <ux-molecules-banner-auth
    :description="$t(`ux.molecules.bookingFunnel.${userLocatedInAPZone ? 'APZone' : 'AuthBanner'}.description`)"
    :link="{
      href: loginLink,
      text: $t(`ux.molecules.bookingFunnel.${userLocatedInAPZone ? 'APZone' : 'AuthBanner'}.login`)
    }"
    :step="currentStep"
    :title="$t(`ux.molecules.bookingFunnel.${userLocatedInAPZone ? 'APZone' : 'AuthBanner'}.title`)"
    v-if="displayBanner"
  />
  <form @submit.prevent class="Booking-funnel" ref="bookingFunnel">
    <template v-if="currentStep === 1">
      <ux-atoms-form-field-set
        :legend="$t('ux.molecules.bookingFunnel.peopleNumber')"
        v-if="availabilityContentDisplayed !== availabilityContentDisplayedType.NO_AVAILABILITY"
        variant="booking"
      >
        <ux-atoms-form-select
          :label="$t('ux.molecules.bookingFunnel.selectPeople')"
          :max="BOOKING_FUNNEL_MAX"
          :min="BOOKING_FUNNEL_MIN"
          :model-value="peopleNumberModel"
          :options="getSelectOptions()"
          @update:model-value="updatePeopleNumber"
          label-is-sr
        />
      </ux-atoms-form-field-set>
      <div v-if="availabilityContentDisplayed !== availabilityContentDisplayedType.NO_AVAILABILITY">
        <div class="Booking-funnel__message" v-if="peopleNumberModel >= BOOKING_FUNNEL_MAX">
          <ux-atoms-typo :text="$t('ux.molecules.bookingFunnel.tooManyPeople')" as="p" />
          <div class="Booking-funnel__contact">
            <ux-atoms-link :href="phoneTo" :text="restaurantPhoneNumber" />
            <ux-atoms-link :href="mailTo" :text="restaurant.mail" />
          </div>
        </div>

        <ux-atoms-form-field-set :legend="$t('ux.molecules.bookingFunnel.dateAndTime')" class="Booking-funnel__dates" v-else variant="booking">
          <ux-atoms-button
            :label="$t('ux.molecules.bookingFunnel.moreDates')"
            @click="toggleDatePicker"
            class="Booking-funnel__dates-more"
            ref="datePickerButton"
            variant="link"
          />
          <ux-molecules-modal
            :event-element="datePickerButton"
            :model-value="modalDatePickerIsOpen"
            @update:model-value="closeModal($event)"
            button-no-border
            button-position="right"
            has-no-separator
            is-sub-modal
            size="small"
          >
            <ux-molecules-date-picker
              :model-value="selectedDate"
              @date-picker::update-date="updateSelectedDate"
              class="Booking-funnel__date-picker"
            />
          </ux-molecules-modal>

          <ux-molecules-date-selector
            :days-with-availabilities="daysWithAvailabilities"
            :selected-date="selectedDate"
            :start-date="startDate"
            @date::select="updateSelectedDate"
          />
          <div class="Booking-funnel__offer" v-if="bookRestaurantStore.displayWarning && !nextAvailabilityLoading">
            <ux-atoms-typo as="p" class="Booking-funnel__offer-warning-error" text-align="start" variant="text-regular">
              {{ t('ux.molecules.bookingFunnel.offerWarning', { name: bookRestaurantStore.selectedOffer?.name }) }}
            </ux-atoms-typo>
            <ux-atoms-typo as="p" class="Booking-funnel__offer-warning-content" text-align="start" v-if="nextAvailability" variant="text-regular">
              {{ t('ux.molecules.bookingFunnel.offerWarningMessage') }}
            </ux-atoms-typo>
            <ux-atoms-button
              :label="$t('ux.molecules.bookingFunnel.seeNextAvailability', { date: nextAvailabilityFormattedDate })"
              @click="displayAvailabilityWithoutOffer"
              v-if="nextAvailability"
              variant="outlined"
            ></ux-atoms-button>
          </div>
          <ux-molecules-time-selector
            :date="selectedDate"
            :pre-selected-booking="timeSelectorDefaultValues"
            :times="timesForSpecificDate"
            @time::select="updateSelectedTime"
            v-if="availabilityContentDisplayed === availabilityContentDisplayedType.TIME_SELECTOR && selectedDateHasAvailabilities"
          />
          <div v-else-if="nextAvailability && !bookRestaurantStore.displayWarning">
            <hr class="Booking-funnel__hr" />
            <div class="position-flex position-flex--center mt-6">
              <ux-atoms-button
                :label="$t('ux.molecules.bookingFunnel.seeNextAvailability', { date: nextAvailabilityFormattedDate })"
                @click="displayNextAvailability"
                v-if="nextAvailability"
                variant="outlined"
              />
            </div>
          </div>
        </ux-atoms-form-field-set>
      </div>
      <div
        class="Booking-funnel__message"
        v-if="
          (availabilityContentDisplayed === availabilityContentDisplayedType.NO_AVAILABILITY ||
            (availabilityContentDisplayed === availabilityContentDisplayedType.TIME_SELECTOR && !nextAvailability)) &&
          !nextAvailabilityLoading &&
          !loadingLazySingleAvailabilities
        "
      >
        <ux-atoms-typo
          as="p"
          class="Booking-funnel__offer-warning-error"
          text-align="start"
          v-if="bookRestaurantStore.selectedOffer?.name"
          variant="text-regular"
        >
          {{ t('ux.molecules.bookingFunnel.offerWarning', { name: bookRestaurantStore.selectedOffer?.name }) }}
        </ux-atoms-typo>
        <ux-atoms-typo as="p" text-align="start" v-else variant="text-regular">
          {{ t('ux.molecules.bookingFunnel.noAvailability') }}
        </ux-atoms-typo>
        <div class="Booking-funnel__contact" v-if="displayContactBlock">
          <ux-atoms-link
            :href="phoneTo"
            :text="'(+' + restaurant.phonePrefix + ') ' + restaurant.phone"
            @click="gtmPhoneNoAvailabilitiesEvent"
            v-if="restaurant?.phone && restaurant?.phonePrefix"
          />
          <ux-atoms-link :href="mailTo" :text="restaurant.mail" v-if="restaurant?.mail" />
        </div>
      </div>
      <div v-if="nextAvailabilityLoading || loadingLazySingleAvailabilities">
        <ux-atoms-loader aria-label="Loading" />
      </div>
      <template v-if="!bookRestaurantStore?.displayWarning && peopleNumberModel !== BOOKING_FUNNEL_MAX">
        <ux-molecules-booking-offers
          :currency-symbol="currencySymbol"
          :is-standard="selectedAvailability?.isStandard as boolean"
          :key="selectedTime"
          :offers="availabilityOffers"
          :pre-selected-offer="selectedOfferFromStore"
          :restaurant-timezone="restaurant.timezoneId as string"
          @booking-offers::selected-offer="updateSelectedOffer"
          id="Booking-funnel__offers"
          v-if="availabilityOffers?.length"
        />
      </template>
      <div
        v-if="
          selectedTime &&
          peopleNumberModel !== BOOKING_FUNNEL_MAX &&
          !bookRestaurantStore.displayWarning &&
          !(availabilityContentDisplayed === availabilityContentDisplayedType.TIME_SELECTOR && !nextAvailability)
        "
      >
        <ux-atoms-typo :text="$t('ux.molecules.bookingFunnel.commentSection')" as="label" color="dark" variant="text-heading-01" />
        <ux-atoms-typo :text="$t('ux.molecules.bookingFunnel.commentSectionDescription')" as="p" class="mb-3" color="light" variant="text-small" />
        <ux-atoms-form-text-area
          :error="errorCommentSection"
          :error-text="$t('ux.molecules.bookingFunnel.commentSectionError')"
          :id="`comment-section-${uuid}`"
          :label="$t('ux.molecules.bookingFunnel.commentSectionDescription')"
          :name="`comment-section-${uuid}`"
          v-model="commentSection"
        />
      </div>
    </template>

    <template v-if="currentStep === 2">
      <ux-molecules-booking-notice
        :charge-amount="chargeAmount"
        :currency-symbol="currencySymbol"
        :terms-and-conditions="termsAndConditions"
        :variant="variantBookingNotice"
        v-if="!!variantBookingNotice"
      />
      <ux-organismes-customer-form ref="customerFormRef" />
      <ux-atoms-typo as="p" color="light" variant="text-small">
        <i18n-t keypath="ux.molecules.bookingFunnel.termsOfUse">
          <ux-atoms-link :to="localePath(t('ux.molecules.bookingFunnel.termsOfUseLink'))" target="_blank" underline>
            {{ t('ux.molecules.bookingFunnel.termsOfUseTextLink') }}
          </ux-atoms-link>
        </i18n-t>
      </ux-atoms-typo>
    </template>
    <template v-if="currentStep === 3">
      <ux-molecules-booking-funnel-credit-card
        :availability="selectedAvailability"
        :guests="peopleNumberModel"
        :offer="selectedOffer"
        :prepayment-amount="chargeAmount"
        :restaurant="restaurant"
        @booking-funnel-credit-card::go-back="previousStep"
        @booking-funnel-credit-card::validation-changed="changeButtonState"
        ref="creditCardFormRef"
      />
    </template>
    <div class="Booking-funnel__text" v-if="peopleNumberModel !== BOOKING_FUNNEL_MAX">
      <ux-atoms-typo :class="['Booking-funnel__policy', { 'Booking-funnel__policy--full': textIsFull }]" as="p" color="light" variant="text-small">
        <i18n-t keypath="ux.molecules.bookingFunnel.privacyPolicy">
          <ux-atoms-link :to="`mailto:${t('ux.molecules.bookingFunnel.privacyPolicy1stTextLink')}`" @keyup="focusPolicyVisibleLink($event)" underline>
            {{ t('ux.molecules.bookingFunnel.privacyPolicy1stTextLink') }}
          </ux-atoms-link>
          <ux-atoms-link
            :to="localePath(t('ux.molecules.bookingFunnel.privacyPolicy2ndLink'))"
            @keyup="focusPolicyVisibleLink($event)"
            class="Booking-funnel__text-link"
            target="_blank"
            underline
          >
            {{ t('ux.molecules.bookingFunnel.privacyPolicy2ndTextLink') }}
          </ux-atoms-link>
        </i18n-t>
      </ux-atoms-typo>
      <ux-atoms-button
        :label="$t('ux.molecules.bookingFunnel.readMore')"
        @button::click="showText"
        class="Booking-summary__detail-link"
        size="s"
        v-if="!textIsFull"
        variant="link"
      />
    </div>
    <ux-molecules-booking-summary
      :booking-error="bookingError"
      :date="updatedStartDate ?? selectedDate"
      :guests="peopleNumberModel"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      :step="currentStep"
      :time="selectedTime"
      @booking-summary::confirm="nextStep"
      @booking-summary::go-back="previousStep"
    />
    <Transition name="fade">
      <div class="Booking-funnel__overlay" v-if="bookRestaurantStore.displayOverLay"></div>
    </Transition>
  </form>
</template>
<script lang="ts" setup>
import { BookingNoticeVariant } from '~/components/ux/molecules/BookingNotice/types';
import { Partners } from '~/core/constants';
import { AvailabilitySlot, Booking, Offer, Restaurant, RestaurantOffers, useSingleAvailabilitiesAsyncData } from '~/graphql';
import { getFirstFocusableElement } from '~/helpers/accessibility';
import { BOOKING_FUNNEL_MAX, BOOKING_FUNNEL_MIN } from '~/helpers/constants';
import { dateValue, daysDifference } from '~/helpers/date';
import { gtmEvent, gtmPageAndUser } from '~/helpers/gtm';
import { useScrollToContainer } from '~/helpers/scrollToContainer';
import { getSelectOptions } from '~/helpers/selectOptions';
import creditCardForm from '~/molecules/BookingFunnelCreditCard/BookingFunnelCreditCard.vue';
import CustomerForm from '~/organismes/CustomerForm/CustomerForm.vue';
import { useBookRestaurantStore } from '~/stores/bookRestaurantStore';

export interface BookingFunnelProps {
  currencySymbol?: null | string;
  offers?: RestaurantOffers[];
  termsAndConditions: string;
}

type Emits = (e: 'componentReady') => void;

const emits = defineEmits<Emits>();

const props = withDefaults(defineProps<BookingFunnelProps>(), {
  currencySymbol: undefined,
  offers: undefined,
  termsAndConditions: ''
});

const { getAuthUrl } = useAuth();

const loginLink = computed(() => {
  return getAuthUrl('login');
});

const { locale, t } = useI18n();
const route = useRoute();
const localePath = useLocalePath();
const bookRestaurantStore = useBookRestaurantStore();
const userStore = useUserStore();
const uuid = useId();
const bookingFunnel = ref();

const { nextAvaibilityQuery, nextAvailability, nextAvailabilityLoading, searchParams } = useSearchRestaurant();

const restaurant = ref<Restaurant>(bookRestaurantStore.selectedRestaurantData as Restaurant);
const peopleNumberModel = ref<number>(bookRestaurantStore.preSelectedBooking?.people || searchParams.value.groupSize);
const bookingError = ref<boolean>(false);

const currentStep = ref<number>(1);
const startDate = ref<string>(bookRestaurantStore.preSelectedBooking?.date || searchParams.value.date);
const selectedDate = ref<string>(bookRestaurantStore.preSelectedBooking?.date || searchParams.value.date);
const selectedTime = ref(bookRestaurantStore.preSelectedBooking?.time ?? searchParams.value.slot);
const requiredCreditCardSlot = ref<boolean>(false);
const creditCardFormValidity = ref<boolean>(false);
const textIsFull = ref<boolean>(false);
const modalDatePickerIsOpen = ref<boolean>(false);
const modalDropdownTarget = ref<HTMLElement | null>(null);
const datePickerButton = ref<HTMLElement>();
const availabilitiesStartDate = ref<string>(startDate.value);
const selectedOffer = ref<RestaurantOffers | null>(null);
const customerInfo = ref<any>({});
const commentSection = ref<string>('');

const timeSelectorDefaultValues = computed(() => {
  return bookRestaurantStore.preSelectedBooking ?? { date: selectedDate.value, people: peopleNumberModel.value, time: selectedTime.value };
});

const isLoading = ref<boolean>(false);

const displayBanner = computed(() => {
  // remove :  && !isWebView.value
  return !userStore.isLogged && [1, 2].includes(currentStep.value);
});

const errorCommentSection = computed(() => commentSection.value.length >= 255);

const restaurantPhoneNumber = computed(() => {
  if (!restaurant.value?.phone) return '';
  const phonePrefix = restaurant.value.phonePrefix ? `+${restaurant.value.phonePrefix} ` : '';
  return phonePrefix + restaurant.value.phone;
});

enum availabilityContentDisplayedType {
  TIME_SELECTOR,
  NEXT_AVAILABILITY,
  NO_AVAILABILITY,
  MAX_GUESTS
}

const availabilityContentDisplayed = computed(() => {
  if (!bookRestaurantStore.displayWarning) {
    return availabilityContentDisplayedType.TIME_SELECTOR;
  } else if (nextAvailability.value) {
    return availabilityContentDisplayedType.NEXT_AVAILABILITY;
  } else if (peopleNumberModel.value >= BOOKING_FUNNEL_MAX) {
    return availabilityContentDisplayedType.MAX_GUESTS;
  }

  return availabilityContentDisplayedType.NO_AVAILABILITY;
});

const selectedDateHasAvailabilities = computed(() => {
  return daysWithAvailabilities.value.some((date) => selectedDate.value.includes(date));
});

const nextAvailabilityFormattedDate = computed(() => {
  const date = nextAvailability.value ? new Date(nextAvailability.value?.date) : new Date();
  const dateFormat = locale.value === 'fr' ? 'dd MMM' : 'MMM, do';
  return useDateFormat(date, dateFormat);
});

const userLocatedInAPZone = computed(() => userStore.APZone);

const variantBookingNotice = computed(() => {
  const hasPaiementPartner = !!restaurant.value.identifiers?.some((identifier) => identifier?.system && !Partners.includes(identifier.system));

  if (hasPaiementPartner) {
    if (selectedOffer.value?.requireDeposit) {
      return BookingNoticeVariant.Deposit;
    }

    if (selectedOffer.value?.requireCreditCard || requiredCreditCardSlot.value) {
      return BookingNoticeVariant.CreditCard;
    }
  } else if (requiredCreditCardSlot.value || selectedOffer.value?.requireCreditCard || selectedOffer.value?.requireDeposit) {
    return BookingNoticeVariant.GuaranteeNoPartner;
  }
});

const gtmPageName = 'restaurantsandbars::booking::steps';

const customerFormRef = ref<typeof CustomerForm>();
const creditCardFormRef = ref<typeof creditCardForm>();
const { bookTable, bookingPaiement } = useBooking();
const { confirm3DSecurePayment, createPaymentMethod, initializeStripe } = useStripe();

const selectedOfferFromStore = computed(() => {
  const offer = bookRestaurantStore.selectedOffer;
  if (!offer) return undefined;

  if (offer.isAccorPlus) {
    return userStore.APZone && userStore.isLogged && userStore.isAPUser ? offer : undefined;
  }

  return offer;
});

bookRestaurantStore.$subscribe(
  (_mutation, state) => {
    if (state.preSelectedBooking) {
      if (!state.displayWarning) {
        selectedDate.value = state.preSelectedBooking.date;
        selectedTime.value = state.preSelectedBooking.time;
        peopleNumberModel.value = state.preSelectedBooking.people;
      } else if (state.preSelectedBooking.date) {
        nextAvailability.value = {
          date: state.preSelectedBooking.date,
          slot: state.preSelectedBooking.time
        };
      }
      if (state.selectedOffer) {
        selectedOffer.value = state.selectedOffer;
      }
    }
  },
  { immediate: true }
);
const bookingState = useState<any>('bookingState', () => null);

const { data: initialAvailabilities } = await useSingleAvailabilitiesAsyncData({
  from: selectedDate.value,
  groupSize: peopleNumberModel.value,
  realTime: true,
  singleAvailabilitiesId: bookRestaurantStore.selectedRestaurantData?.id as string,
  to: dateValue(6, selectedDate.value) as string
});

const {
  data: updatedAvailabilities,
  handleAvailabilitiesParamChange,
  loadingLazySingleAvailabilities
} = useRestaurantAvailabilities({
  from: selectedDate.value,
  groupSize: peopleNumberModel.value,
  realTime: true,
  singleAvailabilitiesId: bookRestaurantStore.selectedRestaurantData?.id as string,
  to: dateValue(6, selectedDate.value) as string
});

const availabilities = computed(() => {
  if (updatedAvailabilities.value) {
    return updatedAvailabilities.value?.singleAvailabilities;
  }
  return initialAvailabilities.value?.singleAvailabilities;
});

const phoneTo = computed(() => {
  return `tel:+${restaurant.value?.phone}`;
});

const mailTo = computed(() => {
  return `mailto:${restaurant.value?.mail}`;
});

const updatedStartDate = computed(() => {
  return selectedDate.value === startDate.value ? firstAvailableDate.value : selectedDate.value;
});

const timesForSpecificDate = computed<AvailabilitySlot[] | null>(
  () => availabilities.value?.filter((time): time is AvailabilitySlot => time?.date === updatedStartDate.value) ?? null
);

const timesForSelectedDate = computed(() => {
  return availabilities.value?.filter((time: AvailabilitySlot | null) => time?.date === selectedDate.value);
});

const daysWithAvailabilities = computed(() => {
  if (!availabilities.value || availabilities.value?.length === 0) {
    return [];
  }

  const availabilitiesDate = availabilities?.value?.map((availability) => availability?.date).filter((el) => Boolean(el));

  return [...new Set<string>(availabilitiesDate as string[])];
});

const displayContactBlock = computed(() => (restaurant?.value?.phone && restaurant?.value?.phonePrefix) || restaurant?.value.mail);

const firstAvailableDate = computed(() => {
  return daysWithAvailabilities.value[0];
});
const closeModal = (e: boolean) => {
  if (!e) {
    modalDatePickerIsOpen.value = e;
  }
};
const toggleDatePicker = (event: Event) => {
  modalDropdownTarget.value = event.target as HTMLElement;
  nextTick(() => {
    modalDatePickerIsOpen.value = !modalDatePickerIsOpen.value;
  });
};

const updatePeopleNumber = (people: number) => {
  bookRestaurantStore.selectedOffer = undefined;
  if (bookRestaurantStore.preSelectedBooking) {
    bookRestaurantStore.preSelectedBooking = {
      date: bookRestaurantStore.preSelectedBooking.date,
      people,
      time: bookRestaurantStore.preSelectedBooking.time
    };
  } else {
    peopleNumberModel.value = people;
  }
};

const updateSelectedDate = (date: string) => {
  bookRestaurantStore.selectedOffer = undefined;
  if (bookRestaurantStore.preSelectedBooking) {
    bookRestaurantStore.preSelectedBooking = {
      date,
      people: bookRestaurantStore.preSelectedBooking.people,
      time: bookRestaurantStore.preSelectedBooking.time
    };
  } else {
    selectedDate.value = date;
  }

  modalDatePickerIsOpen.value = false;
  resetTime();
};

const updateSelectedTime = (time: AvailabilitySlot) => {
  bookRestaurantStore.selectedOffer = undefined;
  if (bookRestaurantStore.preSelectedBooking) {
    bookRestaurantStore.preSelectedBooking = {
      date: bookRestaurantStore.preSelectedBooking.date,
      people: bookRestaurantStore.preSelectedBooking.people,
      time: time.slot
    };
  } else {
    selectedTime.value = time.slot;
    selectedDate.value = time.date;
  }

  gtmEvent('bloc_interact', {
    bloc_interaction: `time-${selectedTime.value}`,
    bloc_name: 'step 1',
    pagename: gtmPageName
  });

  const currentSelectedOfferExistOnTheTimeSlotSelected = timesForSelectedDate.value?.some((availability) =>
    availability?.offers?.some((el) => el?.id?.toString() === bookRestaurantStore?.selectedOffer?.id)
  );
  const selectedSlot = timesForSelectedDate?.value?.find((slot) => slot?.date === selectedDate.value && slot?.slot === time.slot);

  if (!currentSelectedOfferExistOnTheTimeSlotSelected) {
    // this force to unselect the current offer in case this offer doesn't exist for time slot selected
    updateSelectedOffer(undefined);
  }

  if (availabilityOffers?.value?.length || (Array.isArray(selectedSlot?.offers) && selectedSlot?.offers.length > 0)) {
    nextTick(() => {
      useScrollToContainer('Booking-funnel__offers', 'Booking-funnel');
    });
  }
};

const resetTime = () => {
  if (bookRestaurantStore.preSelectedBooking) {
    bookRestaurantStore.preSelectedBooking = {
      date: bookRestaurantStore.preSelectedBooking.date,
      people: bookRestaurantStore.preSelectedBooking.people,
      time: ''
    };
  }
  selectedTime.value = '';
  bookRestaurantStore.refreshTimes();
};

const selectedAvailability = computed(() => {
  if (!selectedTime.value || !selectedDate.value) {
    return null;
  }
  return availabilities.value?.find((availability) => availability?.date === selectedDate.value && availability?.slot === selectedTime.value);
});

const searchNextAvailability = async () => {
  await nextAvaibilityQuery({
    from: selectedDate.value ?? searchParams.value.date,
    groupSize: peopleNumberModel.value ?? searchParams.value.groupSize,
    restaurantId: restaurant.value.id
  });
};

const chargeAmount = computed<number>(() => {
  return Number(
    selectedAvailability?.value?.offers?.find((offer) => offer?.id?.toString() === selectedOffer?.value?.id)?.feeAmount ??
      selectedOffer?.value?.chargeAmount
  );
});

const availabilityOffers: ComputedRef<RestaurantOffers[]> = computed(() => {
  if (!props.offers || !selectedAvailability.value || !selectedAvailability.value?.offers?.length || !props.offers?.length) {
    return [];
  }

  const offerMap = new Map<number, Offer | null>(selectedAvailability.value?.offers?.map((offer: Offer | null) => [Number(offer?.id), offer]));

  return props.offers?.filter((offer) => offerMap.has(Number(offer?.id)));
});

const updateSelectedOffer = (offer?: RestaurantOffers) => {
  bookRestaurantStore.selectedOffer = offer;
  selectedOffer.value = offer ?? null;
};

const nextStep = async () => {
  isLoading.value = true;
  try {
    if (currentStep.value === 1) {
      incrementStep();
      gtmEvent('bloc_interact', {
        bloc_interaction: 'confirm reservation',
        bloc_name: 'step 1',
        pagename: gtmPageName
      });
    } else if (currentStep.value === 2) {
      await handleCustomerFormStep();
    } else if (currentStep.value === 3) {
      await handlePaymentStep();
    }
  } catch (error) {
    bookingError.value = true;
  }
  isLoading.value = false;

  await nextTick(() => {
    const firstEl = getFirstFocusableElement(bookingFunnel.value) as HTMLElement;
    firstEl?.focus();
  });
};

const incrementStep = () => {
  currentStep.value++;

  if (textIsFull.value) {
    textIsFull.value = false;
  }

  const container = document.querySelector('#Booking-funnel-id');
  if (container) {
    container.scrollTo(0, 0);
  }
};

const handleCustomerFormStep = async () => {
  if (!customerFormRef.value) return;

  const { firstErrorField, formData, valid } = customerFormRef.value.validateForm();
  customerInfo.value = formData;

  if (!valid) {
    useScrollToContainer(firstErrorField, 'Booking-funnel');
    return;
  }

  gtmEvent('booking_form_submit', {
    pagename: gtmPageName,
    room_booking: 'true'
  });

  const booking = await createBooking(formData);
  if (!booking) {
    bookingError.value = true;
    return;
  }

  updateBookingState(booking);
  await handleBookingNavigation(booking);
};

const gtmPhoneNoAvailabilitiesEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'phone call',
    bloc_name: 'step 1',
    pagename: gtmPageName
  });
};

const createBooking = async (formData: any) => {
  const { email, firstName, lastName, phoneNumber } = formData;
  return await bookTable(
    restaurant.value.id,
    peopleNumberModel.value,
    selectedDate.value,
    selectedTime.value!,
    firstName,
    lastName,
    email,
    phoneNumber,
    selectedOffer?.value?.id,
    commentSection.value
  );
};

const updateBookingState = (booking: Booking) => {
  bookingState.value = {
    BookingId: booking.id,
    bookingDate: booking.bookingDate,
    bookingTime: booking.bookingTime,
    countrySlug: restaurant.value.countrySlug,
    groupSize: booking.groupSize,
    restaurantFood: restaurant.value.foodType,
    restaurantId: restaurant.value.id,
    restaurantName: restaurant.value.name,
    timezoneId: restaurant.value.timezoneId
  };
};

const handleBookingNavigation = async (booking: Booking) => {
  if (booking?.publishableKey) {
    incrementStep();
    await initializeStripe(booking.publishableKey, booking.accountId);
  } else {
    navigateToConfirmation(booking.id);
  }
};

const navigateToConfirmation = (bookingId?: null | string) => {
  navigateTo(localePath(`/booking/confirmation/${restaurant.value.id}/${bookingId}`));
};

const handlePaymentStep = async () => {
  if (!creditCardFormRef.value) return;

  const { valid, zipCode } = creditCardFormRef.value.validateForm();
  creditCardFormValidity.value = valid;

  if (valid) {
    await processPayment(zipCode);
  }
};

const processPayment = async (zipCode: string) => {
  const { email, firstName, lastName, phoneNumber } = customerInfo.value;
  const stripePayment = await createPaymentMethod(firstName, lastName, zipCode);
  const paymentInfo = await bookingPaiement(
    restaurant.value.id,
    bookingState.value?.BookingId,
    peopleNumberModel.value,
    selectedDate.value,
    selectedTime.value!,
    stripePayment?.paymentMethod?.id as string,
    firstName,
    lastName,
    email,
    phoneNumber,
    zipCode,
    selectedOffer?.value?.id as string
  );
  await handlePaymentConfirmation(paymentInfo, zipCode, stripePayment?.paymentMethod?.id);
};

// Handle Payment Confirmation
const handlePaymentConfirmation = async (paymentInfo: Booking, zipCode: string, stripePaymentId?: string) => {
  if (paymentInfo?.paymentIntentSecret) {
    const paymentIntent = await confirm3DSecurePayment(paymentInfo.paymentIntentSecret);
    if (!paymentIntent || paymentIntent?.error) {
      bookingError.value = true;
    } else {
      await finalizePayment(zipCode, paymentIntent?.paymentIntent?.id, stripePaymentId);
    }
  } else {
    navigateToConfirmation(paymentInfo.id);
  }
};

// Finalize Payment
const finalizePayment = async (zipCode: string, paymentIntentId?: string, stripePaymentId?: string) => {
  const { email, firstName, lastName, phoneNumber } = customerInfo.value;
  const bookingPaimentResults = await bookingPaiement(
    restaurant.value.id,
    bookingState.value?.BookingId,
    peopleNumberModel.value,
    selectedDate.value,
    selectedTime.value!,
    stripePaymentId as string,
    firstName,
    lastName,
    email,
    phoneNumber,
    zipCode,
    selectedOffer?.value?.id as string,
    paymentIntentId
  );

  if (!bookingPaimentResults) {
    bookingError.value = true;
  } else {
    navigateToConfirmation(bookingPaimentResults.id);
  }
};

const previousStep = () => {
  if (currentStep.value === 2) {
    const customerForm = customerFormRef?.value?.validateForm();
    userStore.userBookingForm = customerForm.formData;
  }

  startDate.value = selectedDate.value;
  currentStep.value = 1;

  nextTick(() => {
    const firstEl = getFirstFocusableElement(bookingFunnel.value) as HTMLElement;

    // This is to prevent the select unfold from the previous keypress event
    firstEl.addEventListener(
      'keypress',
      function (e) {
        e.preventDefault();
      },
      { once: true }
    );
    firstEl?.focus();
  });
};

const changeButtonState = (valid: boolean) => {
  creditCardFormValidity.value = valid;
};
const showText = () => {
  textIsFull.value = true;
};

const displayAvailabilityWithoutOffer = () => {
  bookRestaurantStore.displayWarning = false;
  if (bookRestaurantStore.preSelectedBooking) {
    selectedDate.value = bookRestaurantStore.preSelectedBooking.date;
    selectedTime.value = bookRestaurantStore.preSelectedBooking.time;
    peopleNumberModel.value = bookRestaurantStore.preSelectedBooking.people;
  } else {
    selectedDate.value = startDate.value;
  }
};

const displayNextAvailability = () => {
  if (!nextAvailability.value) return;

  bookRestaurantStore.preSelectedBooking = {
    date: nextAvailability.value?.date,
    people: peopleNumberModel.value,
    time: nextAvailability.value?.slot
  };
};

const focusPolicyVisibleLink = (e: KeyboardEvent) => {
  // TODO: keyCode is deprecated, we should use e.key instead : https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
  if (e.keyCode === 9) {
    textIsFull.value = true;

    nextTick(() => {
      const container = document.querySelector('#Booking-funnel-id');
      if (container) {
        container.scrollTo(0, 1000);
      }
    });
  }
};

const isDisabled = computed(() => {
  if (currentStep.value === 1) {
    return (
      (availabilityContentDisplayed.value === availabilityContentDisplayedType.TIME_SELECTOR && !nextAvailability.value) ||
      bookRestaurantStore.displayWarning ||
      selectedTime.value === undefined ||
      selectedTime.value === '' ||
      selectedDate.value === undefined ||
      peopleNumberModel.value === undefined ||
      peopleNumberModel.value === 7 ||
      (bookRestaurantStore.selectedOffer?.isAccorPlus && (!userStore.APZone || (userStore.APZone && !userStore.isLogged))) ||
      errorCommentSection.value
    );
  }
  if (currentStep.value === 3) {
    return !creditCardFormValidity.value;
  }
  return false;
});
watch(
  () => peopleNumberModel.value,
  () => {
    gtmEvent('bloc_interact', {
      bloc_interaction: `people-${peopleNumberModel.value}`,
      bloc_name: 'step 1',
      pagename: gtmPageName
    });

    if (peopleNumberModel.value >= BOOKING_FUNNEL_MAX) {
      return;
    }

    handleAvailabilitiesParamChange({
      from: selectedDate.value,
      groupSize: peopleNumberModel.value,
      realTime: true,
      singleAvailabilitiesId: bookRestaurantStore.selectedRestaurantData?.id as string,
      to: dateValue(6, selectedDate.value) as string
    });
  }
);

watch(
  () => selectedDate.value,
  () => {
    handleAvailabilitiesParamChange({
      from: selectedDate.value,
      groupSize: peopleNumberModel.value,
      realTime: true,
      singleAvailabilitiesId: bookRestaurantStore.selectedRestaurantData?.id as string,
      to: dateValue(6, selectedDate.value) as string
    });

    availabilitiesStartDate.value = selectedDate.value;
  }
);

watch(
  () => timesForSelectedDate.value,
  () => {
    if (
      (!route.query.nextAvailabilityQuery || route.query.nextAvailabilityQuery !== 'true') &&
      peopleNumberModel.value < BOOKING_FUNNEL_MAX &&
      !nextAvailability.value
    ) {
      searchNextAvailability();
    }
  },
  { immediate: true }
);

watch(
  () => timeSelectorDefaultValues.value,
  (value) => {
    const timeSlot = timesForSpecificDate.value?.find((slot) => slot.slot === value?.time && slot.date === value.date);
    if (timeSlot) {
      requiredCreditCardSlot.value = timeSlot.requireCreditCard ?? false;
    }
  },
  { immediate: true }
);

onMounted(() => {
  emits('componentReady');

  gtmPageAndUser({
    brandcontext: 'restaurantsandbars',
    category: 'restaurantsandbars',
    countrymarket: 'fr',
    currencycode: 'EUR',
    daysbeforearrival: route?.query?.date ? daysDifference(route?.query.date as string) : 0,
    dynamic_datas_ready: true,
    event: 'push_dynamic_datas',
    food_type: restaurant.value.foodType as string | undefined,
    hotelbrandcode: restaurant.value.brandCode as string | undefined,
    orderamount: 0, // TODO: plug order amount once it's available
    pagename: 'restaurantsandbars::booking::steps',
    pagetype: 'restaurantsandbars',
    restaurant_code: restaurant.value.id as string | undefined,
    restaurant_name: restaurant.value.name as string | undefined,
    sub_category_level1: 'booking',
    sub_category_level2: 'steps'
  });
});
</script>
<style lang="scss" scoped>
@use 'BookingFunnel.scss';
</style>
