import { Stripe, StripeElementLocale, StripeElements, loadStripe } from '@stripe/stripe-js';

const styles = {
  base: {
    color: '#3E3d48',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px'
  },
  invalid: {
    color: '#3E3D48'
  }
};

const stripe: Ref<Stripe | null> = ref(null);
const elements: Ref<StripeElements | undefined> = ref(undefined);
const cardNumberError = ref('');
const cardExpiryError = ref('');
const cardCvcError = ref('');

export function useStripe() {
  const initializeStripe = async (publishableKey: string, accountId?: null | string) => {
    const locale = useNuxtApp().$i18n.locale;

    stripe.value = accountId
      ? await loadStripe(publishableKey, { locale: locale.value as StripeElementLocale, stripeAccount: accountId })
      : await loadStripe(publishableKey, { locale: locale.value as StripeElementLocale });

    elements.value = stripe?.value?.elements();
    const cardNumber = elements?.value?.create('cardNumber', {
      placeholder: '',
      style: styles
    });

    const cardExpiry = elements?.value?.create('cardExpiry', {
      placeholder: '',
      style: styles
    });

    const cardCvc = elements?.value?.create('cardCvc', {
      placeholder: '',
      style: styles
    });
    cardNumber?.mount('#card-number-element');
    cardExpiry?.mount('#card-expiry-element');
    cardCvc?.mount('#card-cvc-element');

    // Add event listeners to display errors
    cardNumber?.on('change', (event) => {
      cardNumberError.value = event.error ? event.error.message : '';
    });

    cardExpiry?.on('change', (event) => {
      cardExpiryError.value = event.error ? event.error.message : '';
    });

    cardCvc?.on('change', (event) => {
      cardCvcError.value = event.error ? event.error.message : '';
    });
  };

  const createPaymentMethod = async (firstName: string, lastName: string, zipcode: string) => {
    if (!elements.value) {
      return;
    }

    const cardElement = elements.value.getElement('cardNumber');

    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } =
      (await stripe?.value?.createPaymentMethod({
        billing_details: {
          address: {
            postal_code: zipcode
          },
          name: `${firstName} ${lastName}`
        },
        card: cardElement,
        type: 'card'
      })) || {};
    if (error) {
      return { error };
    }

    return { paymentMethod };
  };

  const confirm3DSecurePayment = async (PaymentIntentSecret: string) => {
    if (!stripe.value) {
      return;
    }
    const { error, paymentIntent } = await stripe.value.handleCardAction(PaymentIntentSecret);

    return { error, paymentIntent };
  };

  return {
    cardCvcError,
    cardExpiryError,
    cardNumberError,
    confirm3DSecurePayment,
    createPaymentMethod,
    initializeStripe,
    stripe
  };
}
