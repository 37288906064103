<template>
  <div :class="['Opening-hours', { 'Opening-hours--1-col': singleCol }]">
    <div class="Opening-hours__groupOpening-hours__group--open">
      <ux-atoms-typo :id="id" as="h3" class="py-2" color="dark" v-if="!isModal" variant="text-heading-01">
        {{ $t('ux.molecules.openingHours.openingHours') }}
      </ux-atoms-typo>
      <table :aria-labelledby="id">
        <tbody>
          <tr :key="day.day" v-for="day in filteredSchedule">
            <th class="Opening-hours__day" scope="row">
              <ux-atoms-typo as="span" color="dark" variant="inherit">
                {{ $t(`days.${day.day.toLowerCase()}`) }}
              </ux-atoms-typo>
            </th>
            <td class="Opening-hours__hours">
              <ux-atoms-typo as="span" color="dark" v-if="day?.openingHours?.length === 0" variant="inherit">
                {{ $t('ux.molecules.openingHours.closed') }}
              </ux-atoms-typo>
              <ux-atoms-typo :key="index" as="span" color="dark" v-for="(hours, index) in day.openingHours" variant="inherit">
                {{ hours?.from }} - {{ hours?.to }}<span v-if="day?.openingHours?.length && index !== day.openingHours.length - 1">,&nbsp;</span>
              </ux-atoms-typo>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="Opening-hours__group Opening-hours__group--closed" v-if="closures?.length">
      <ux-atoms-typo as="p" class="py-2" color="dark" variant="text-heading-01">{{ $t('ux.molecules.openingHours.closed') }}</ux-atoms-typo>
      <ul class="Opening-hours__group-list">
        <li :key="index" class="Opening-hours__group-list__item" v-for="(closure, index) in closures">
          <ux-atoms-typo as="p" color="dark" variant="inherit">
            {{
              $t('ux.molecules.openingHours.fromTo', {
                from: useDateFormat(new Date(closure?.from!), 'P HH:mm', restaurantTimezone),
                to: useDateFormat(new Date(closure?.to!), 'P HH:mm', restaurantTimezone)
              })
            }}
          </ux-atoms-typo>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Period, ScheduleOpenedDay } from '~/graphql/schema';

export interface OpeningHoursProps {
  closures?: Period[];
  isModal?: boolean;
  restaurantTimezone?: string;
  schedule: ScheduleOpenedDay[];
  singleCol?: boolean;
}

const id = useId();
const props = withDefaults(defineProps<OpeningHoursProps>(), {
  closures: undefined,
  isModal: false,
  restaurantTimezone: undefined,
  schedule: undefined,
  singleCol: false
});

const filteredSchedule = computed(() => {
  return props.schedule?.filter(({ day }) => !['BANK_HOLIDAYS', 'EVERYDAY', 'WEEKEND', 'WEEKENDS_AND_BANK_HOLIDAYS'].includes(day));
});
</script>
<style lang="scss" scoped>
@use 'OpeningHours.scss';
</style>
