<template>
  <ux-molecules-restaurant-toolbar :city-name="restaurant?.city" :city-slug="restaurant?.citySlug" :country-slug="restaurant?.countrySlug" />
  <ux-molecules-hero-restaurant
    :medias="restaurant?.medias"
    :restaurant-logo="restaurant?.logoUrl"
    :restaurant-name="restaurant?.name"
    v-if="restaurant"
  />
  <ux-molecules-hero-restaurant-content
    :active-tabs="activeTabs"
    :average-price="restaurant.averagePrice"
    :average-rating="restaurant.averageRating"
    :booking-widget-url="restaurant.bookingWidgetUrl"
    :city="restaurant.city"
    :country-code="restaurant.countryCode"
    :currency-symbol="restaurant.currencySymbol"
    :enrolment-programs="restaurant.enrolmentPrograms"
    :exceptional-closures="restaurant.exceptionalClosures"
    :external-menu-url="restaurant?.externalMenuUrl"
    :facet-codes="restaurant.facetCodes"
    :food-type="restaurant.foodType"
    :identifiers="restaurant.identifiers"
    :initial-availabilities="initialAvailabilities"
    :menu-type="restaurant?.menuType"
    :menus="restaurant?.menus"
    :name="restaurant.name"
    :offers="restaurant.offers"
    :partner-id="restaurant.partnerId"
    :phone="restaurant.phone"
    :phone-prefix="restaurant.phonePrefix"
    :restaurant-timezone="restaurant.timezoneId"
    :review-count="restaurant?.reviewCount"
    :schedule="restaurant.schedule"
    :star="restaurant.star"
    :terms-and-conditions="restaurant.termsAndConditions"
    :zip-code="restaurant.zipCode"
    @navigate-content="handleNavigate"
    v-if="restaurant"
  />
  <section class="Grid-layout Grid-layout--x-space" v-if="restaurant?.offers?.length">
    <div class="Grid-twelve Grid-twelve--subgrid Grid-twelve--restaurant">
      <ux-atoms-typo :id="navTabs.Offers" as="h2" class="py-2" color="dark" variant="expressive-heading-04">
        {{ $t('ux.molecules.restaurantOffers.availableOffers') }}
      </ux-atoms-typo>
      <ux-molecules-restaurant-offers :currency-symbol="restaurant?.currencySymbol" :offers="restaurant?.offers" :restaurant-id="restaurant?.id" />
    </div>
  </section>
  <section class="Grid-layout Grid-layout--x-space" v-if="displayMenuInsert && restaurant">
    <div class="Grid-twelve Grid-twelve--restaurant">
      <ux-atoms-typo :id="navTabs.Menu" as="h2" class="py-2" color="dark" variant="expressive-heading-04">
        {{ $t('ux.molecules.restaurantMealsType.title') }}
      </ux-atoms-typo>
      <ux-molecules-restaurant-meals-type
        :accommodate-meals="restaurant.accommodateMeals"
        :external-menu-url="restaurant?.externalMenuUrl"
        :medias="restaurant.medias"
        :menu-type="restaurant?.menuType"
        :menus="restaurant?.menus"
      />
    </div>
  </section>
  <section class="Grid-layout Grid-layout--x-space" v-if="displayAboutInsert && restaurant">
    <div class="Grid-twelve Grid-twelve--restaurant">
      <ux-atoms-typo as="h2" class="py-2" color="dark" v-if="restaurant?.catchPhrase" variant="expressive-heading-04">
        {{ restaurant?.catchPhrase }}
      </ux-atoms-typo>
      <ux-molecules-restaurant-advantages :advantages="restaurant?.advantages" />
      <ux-molecules-restaurant-descriptions
        :very-long-description-part1="restaurant?.veryLongDescriptionPart1"
        :very-long-description-part2="restaurant?.veryLongDescriptionPart2"
      />
    </div>
  </section>
  <section class="Grid-layout Grid-layout--x-space Layout--bg-grey" v-if="restaurant && customerReview?.length">
    <div class="Grid-twelve Grid-twelve--restaurant my-8">
      <ux-organismes-customers-reviews
        :average-rating="restaurant.averageRating"
        :id="navTabs.Reviews"
        :review-count="restaurant.reviewCount"
        :reviews="customerReview"
      />
    </div>
  </section>
  <section class="Grid-layout Grid-layout--x-space" v-if="restaurant?.lat && restaurant?.lon">
    <div :id="navTabs.Contact" class="Grid-twelve Grid-twelve--restaurant mt-8">
      <ux-atoms-typo as="h2" class="py-2" color="dark" id="address" variant="expressive-heading-04">
        {{ $t('ux.molecules.restaurantMap.locationContact') }}
      </ux-atoms-typo>
    </div>
    <ux-molecules-restaurant-map
      :center="restaurantCoord"
      :city="restaurant.city"
      :email="restaurant.email"
      :facebook="restaurant?.facebook"
      :hotel-name="restaurant?.hotelName"
      :instagram="restaurant?.instagram"
      :markers="[restaurantMarker]"
      :phone="restaurant.phone"
      :phone-prefix="restaurant.phonePrefix"
      :street="restaurant.street"
      :zip-code="restaurant.zipCode"
      class="mt-4"
    />
    <div class="Grid-twelve Grid-twelve--restaurant mt-8" id="schedule" v-if="restaurant.schedule?.length">
      <ux-molecules-opening-hours :closures="restaurant.exceptionalClosures" :schedule="restaurant.schedule" />
    </div>
  </section>
  <section class="Grid-layout Grid-layout--x-space" v-if="selectedLoyaltyBanner">
    <div class="Grid-twelve Grid-twelve--restaurant-large mt-8">
      <ux-molecules-loyalty-banner
        :display-cta="displayLoyaltyBanner"
        :is-accor-plus="selectedLoyaltyBanner.program === LoyaltyProgram.AccorPlus"
        :loyalty-banner="selectedLoyaltyBanner"
        :variant="loyaltyVariant"
      />
    </div>
  </section>
  <ux-molecules-restaurant-breadcrumb :links="footerBreadCrumbs" v-if="footerBreadCrumbs" />
</template>
<script lang="ts" setup>
import type {
  CustomerReview,
  Period,
  Restaurant,
  RestaurantIdentifier,
  RestaurantMedia,
  RestaurantMenu,
  RestaurantOffers,
  ScheduleOpenedDay,
  SingleAvailabilitiesQuery
} from '~/graphql/schema';

import { LinkProps } from '~/components/ux/atoms/Link/Link.vue';
import { EnrolmentPrograms } from '~/components/ux/molecules/CardRestaurant/types';
import { navTabs } from '~/components/ux/molecules/RestaurantNav/types';
import { StarRating } from '~/components/ux/organismes/CustomersReviews/type';
import { LoyaltyProgram, LoyaltyType, LoyaltyUserStatus, LoyaltyVariant } from '~/core';
import {
  useLoyaltyBannersAsyncData,
  useRestaurantAsyncData,
  useRestaurantReviewsAsyncData,
  useSingleAvailabilitiesLazyQuery
} from '~/graphql/operations';
import { focusableElements } from '~/helpers/accessibility';
import { capitalizeFirstLetter } from '~/helpers/capitaliseFirstLetter';
import { dateValue, daysDifference } from '~/helpers/date';
import { gtmPageAndUser } from '~/helpers/gtm';
import { transformOpeningHours } from '~/helpers/review';
import { sanitizeString } from '~/helpers/sanitizeString';
import { useBookRestaurantStore } from '~/stores/bookRestaurantStore';

const config = useRuntimeConfig();
const route = useRoute();
const userStore = useUserStore();
const { baseCountry } = useCountry();
const { t } = useI18n();
const localePath = useLocalePath();

const bookRestaurantStore = useBookRestaurantStore();
const restaurantCode = computed(() => (route.params.restaurantCode ? (route.params.restaurantCode as string).toLocaleUpperCase() : ''));
const { data, error } = await useRestaurantAsyncData({ restaurantId: restaurantCode.value });

useAsyncDataError(error, 'restaurant-slug');

useRedirectIfEmpty(data.value?.restaurant);

const { data: restaurantReviews } = await useRestaurantReviewsAsyncData({ restaurantId: restaurantCode.value });

const setI18nParams = useSetI18nParams();

setI18nParams({
  en: { restaurantCode: restaurantCode.value },
  fr: { restaurantCode: restaurantCode.value }
});

const footerBreadCrumbs = computed<LinkProps[]>(() => {
  const country: LinkProps = {
    href: localePath(`/${data.value?.restaurant?.countrySlug}`),
    text: data.value?.restaurant?.country,
    underline: true
  };

  const city: LinkProps = {
    href: localePath(`/${data.value?.restaurant?.countrySlug}/${data.value?.restaurant?.citySlug}/map`),
    text: data.value?.restaurant?.city,
    underline: true
  };

  const restaurant: LinkProps = {
    disabledLink: true,
    href: localePath(`/restaurant/${data.value?.restaurant?.id}`),
    text: data.value?.restaurant?.name
  };

  return [country, city, restaurant];
});

const activeTabs = computed(() => {
  const tabs = [];

  if (data.value?.restaurant?.offers?.length) {
    tabs.push(navTabs.Offers);
  }

  if (displayMenuInsert.value) {
    tabs.push(navTabs.Menu);
  }

  if (data.value?.restaurant?.lat && data.value?.restaurant?.lon) {
    tabs.push(navTabs.Contact);
  }

  if (customerReview?.value.length) {
    tabs.push(navTabs.Reviews);
  }

  return tabs;
});

const { data: LoyaltyBannerData } = await useLoyaltyBannersAsyncData({
  type: LoyaltyType.Rdp
});

const loyaltyBanners = computed(() => LoyaltyBannerData.value?.loyaltyBanner);

const selectedLoyaltyBanner = computed(() => {
  if (!['LEARN', 'LEARNP'].includes(restaurant.value.enrolmentPrograms.diningOfferParticipation.code)) {
    return null;
  }

  return loyaltyBanners.value
    ?.filter((loyaltyBanner) =>
      userStore.isLogged ? loyaltyBanner?.userStatus === LoyaltyUserStatus.LOGGED_IN : loyaltyBanner?.userStatus === LoyaltyUserStatus.LOGGED_OUT
    )
    .find((loyaltyBanner) => {
      if (userStore.APZone) {
        return loyaltyBanner?.program === LoyaltyProgram.AccorPlus && loyaltyBanner?.type === LoyaltyType.Rdp;
      }

      return loyaltyBanner?.program === LoyaltyProgram.All && loyaltyBanner?.type === LoyaltyType.Rdp;
    });
});

const loyaltyVariant = computed(() => (selectedLoyaltyBanner.value?.program === LoyaltyProgram.All ? LoyaltyVariant.Rdp : LoyaltyVariant.Home));

const displayLoyaltyBanner = computed(() => {
  if (selectedLoyaltyBanner?.value?.program === LoyaltyProgram.All) {
    return Boolean(selectedLoyaltyBanner?.value?.ctaUrl && selectedLoyaltyBanner?.value?.ctaLabel);
  }

  if (selectedLoyaltyBanner?.value?.program === LoyaltyProgram.AccorPlus) {
    return Boolean(!userStore.isLogged && selectedLoyaltyBanner?.value?.ctaUrl && selectedLoyaltyBanner?.value?.ctaLabel);
  }

  return false;
});

const restaurant = computed(() => ({
  accommodateMeals: data.value?.restaurant?.accommodateMeals as string[],
  advantages: data.value?.restaurant?.advantages as string[],
  averagePrice: data.value?.restaurant?.averagePrice as number,
  averageRating: data.value?.restaurant?.averageRating,
  bookingWidgetUrl: data.value?.restaurant?.bookingWidgetUrl,
  brandCode: data.value?.restaurant?.brandCode as string,
  catchPhrase: data.value?.restaurant?.catchPhrase as string,
  city: data.value?.restaurant?.city as string,
  cityMainTitle: data.value?.restaurant?.cityMainTitle as string,
  citySlug: data.value?.restaurant?.citySlug as string,
  countryCode: data.value?.restaurant?.countryCode as string,
  countrySlug: data.value?.restaurant?.countrySlug as string,
  currency: data.value?.restaurant?.currency as string,
  currencySymbol: data.value?.restaurant?.currencySymbol as string,
  defaultFoodType: data.value?.restaurant?.defaultFoodType as string,
  email: data.value?.restaurant?.mail as string,
  enrolmentPrograms: data.value?.restaurant?.enrolmentPrograms as EnrolmentPrograms,
  exceptionalClosures: data.value?.restaurant?.exceptionalClosures as Period[],
  externalMenuUrl: data.value?.restaurant?.externalMenuUrl,
  facebook: data.value?.restaurant?.facebook as string,
  facetCodes: data.value?.restaurant?.facetCodes as string[],
  foodType: data.value?.restaurant?.foodType as string,
  hotelName: data.value?.restaurant?.hotelName as string,
  id: data.value?.restaurant?.id as string,
  identifiers: data.value?.restaurant?.identifiers as RestaurantIdentifier[],
  instagram: data.value?.restaurant?.instagram as string,
  lat: data.value?.restaurant?.lat,
  logoUrl: data.value?.restaurant?.logoUrl,
  lon: data.value?.restaurant?.lon,
  medias: data.value?.restaurant?.medias as RestaurantMedia[],
  menuType: data.value?.restaurant?.menuType,
  menus: data.value?.restaurant?.menus as RestaurantMenu[],
  metaDescription: data.value?.restaurant?.metaDescription as string,
  metaTitle: data.value?.restaurant?.metaTitle as string,
  name: data.value?.restaurant?.name as string,
  offers: data.value?.restaurant?.offers as RestaurantOffers[],
  partnerId: data.value?.restaurant?.partnerId as string,
  phone: data.value?.restaurant?.phone as string,
  phonePrefix: data.value?.restaurant?.phonePrefix as string,
  reviewCount: data.value?.restaurant?.reviewCount as number,
  schedule: data.value?.restaurant?.schedule as ScheduleOpenedDay[],
  star: data.value?.restaurant?.star as number,
  street: data.value?.restaurant?.street as string,
  termsAndConditions: data.value?.restaurant?.termsAndConditions as string,
  timezoneId: data.value?.restaurant?.timezoneId as string,
  veryLongDescriptionPart1: data.value?.restaurant?.veryLongDescriptionPart1 as string,
  veryLongDescriptionPart2: data.value?.restaurant?.veryLongDescriptionPart2 as string,
  zipCode: data.value?.restaurant?.zipCode as string
}));

const customerReview = computed(() => orderCustomersReviews(restaurantReviews?.value?.restaurantReviews?.reviews as CustomerReview[]));

const orderCustomersReviews = (reviews: CustomerReview[] = []): CustomerReview[] => {
  if (!Array.isArray(reviews)) {
    return [];
  }

  return [...reviews].sort((a, b) => {
    const dateA = a.createTime ? new Date(a.createTime).getTime() : 0;
    const dateB = b.createTime ? new Date(b.createTime).getTime() : 0;
    return dateB - dateA;
  });
};

const displayMenuInsert = computed(() => {
  return restaurant.value?.accommodateMeals?.length || restaurant.value?.menus?.length || restaurant.value?.externalMenuUrl;
});

const displayAboutInsert = computed(() => {
  return (
    restaurant.value?.catchPhrase ||
    restaurant.value?.advantages?.length ||
    restaurant.value?.veryLongDescriptionPart1 ||
    restaurant.value?.veryLongDescriptionPart2
  );
});

baseCountry.value = restaurant.value.countrySlug;

bookRestaurantStore.updateSelectedRestaurantData(data.value?.restaurant as Restaurant);

const { searchParams } = useSearchRestaurant();

const { load, loading, onResult } = useSingleAvailabilitiesLazyQuery({
  from: searchParams.value.date,
  groupSize: searchParams.value.groupSize,
  realTime: true,
  singleAvailabilitiesId: bookRestaurantStore.selectedRestaurantData?.id as string,
  to: dateValue(6, searchParams.value.date)
});

const initialAvailabilities = ref<{
  data: SingleAvailabilitiesQuery['singleAvailabilities'] | null;
  loading: boolean;
}>({
  data: null,
  loading: true
});

onMounted(() => {
  if (searchParams.value && bookRestaurantStore.selectedRestaurantData?.id) {
    onResult((result) => {
      if (result.loading) return;

      if (result.data) {
        initialAvailabilities.value = {
          ...initialAvailabilities.value,
          data: result.data.singleAvailabilities,
          loading: false
        };
      }
    });

    load();
  }
});

watch(
  () => loading.value,
  (loading) => {
    initialAvailabilities.value = {
      ...initialAvailabilities.value,
      loading
    };
  }
);

const restaurantCoord = computed(() => {
  return {
    lat: restaurant.value.lat as number,
    lng: restaurant.value.lon as number
  };
});

const restaurantMarker = computed(() => {
  return {
    id: restaurant.value.id,
    name: [restaurant.value.name],
    position: restaurantCoord.value
  };
});
const openPreselectedBooking = () => {
  if (route.query?.book) {
    const booking = {
      date: route.query?.date as string,
      people: parseInt(route.query?.guests as string) as number,
      time: route.query?.slot as string
    };

    bookRestaurantStore.toggleXXSModal(undefined, booking);
  }
};

const handleNavigate = (tabId: string) => {
  const element = document.getElementById(tabId);
  const firstFocusableElement = element?.querySelectorAll(focusableElements)[0] as HTMLElement;

  useFocus(firstFocusableElement, { initialValue: true });
};

const pageEvent = () => {
  gtmPageAndUser({
    brandcontext: 'restaurantsandbars',
    category: 'restaurantsandbars',
    countrymarket: 'fr',
    currencycode: 'EUR',
    daysbeforearrival: route?.query?.date ? daysDifference(route?.query.date as string) : 0,
    dynamic_datas_ready: true,
    event: 'push_dynamic_datas',
    food_type: restaurant.value?.defaultFoodType?.toLowerCase(),
    hotelbrandcode: restaurant.value.brandCode,
    pagename: 'restaurantsandbars::restaurantdetails',
    pagetype: 'restaurantsandbars',
    restaurant_code: restaurant.value.id,
    restaurant_name: restaurant.value.name.toLowerCase(),
    sub_category_level1: 'restaurantdetails'
  });
};

const capitalizedCity = sanitizeString(restaurant.value?.cityMainTitle?.toLowerCase().split(' ').map(capitalizeFirstLetter).join(' '));
const capitalizedRestaurantName = sanitizeString(restaurant.value?.name?.toLowerCase().split(' ').map(capitalizeFirstLetter).join(' '));

useHead(() => ({
  link: [{ href: `${config.public.baseUrl}${route.path}`, rel: 'canonical' }],
  meta: [
    {
      content:
        restaurant?.value?.metaDescription ??
        t('pages.restaurant.description', { cityName: capitalizedCity, restaurantName: capitalizedRestaurantName }),
      name: 'description'
    }
  ],
  title: restaurant?.value?.metaTitle ?? t('pages.restaurant.title', { cityName: capitalizedCity, restaurantName: capitalizedRestaurantName })
}));

const bestReview = computed(() => {
  if (!restaurantReviews.value?.restaurantReviews?.reviews) return null;

  return restaurantReviews.value.restaurantReviews.reviews
    .filter((review) => review?.starRating)
    .reduce((best, review) => {
      const currentRating = StarRating[review?.starRating as keyof typeof StarRating];
      const bestRating = best ? StarRating[best.starRating as keyof typeof StarRating] : 0;

      return currentRating > bestRating ? review : best;
    }, null);
});

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'Restaurant',
  acceptsReservations: true,
  address: {
    '@type': 'PostalAddress',
    addressCountry: restaurant.value.countryCode,
    addressLocality: `${restaurant.value.city} ${restaurant.value.countrySlug}`,
    postalCode: restaurant.value.zipCode,
    streetAddress: restaurant.value.street
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    bestRating: 5,
    ratingValue: restaurant.value.averageRating ?? 0,
    reviewCount: restaurant.value.reviewCount
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: restaurant.value.lat as number,
    longitude: restaurant.value.lon as number
  },
  image: restaurant.value.medias?.[0]?.formats?.[0]?.path
    ? [
        {
          '@type': 'ImageObject',
          url: restaurant.value.medias[0].formats[0].path.toString()
        }
      ]
    : [],
  menu: restaurant.value.externalMenuUrl?.toString(),
  name: restaurant.value.name,
  openingHoursSpecification: transformOpeningHours(restaurant.value.schedule) as any,
  photo: restaurant.value.medias
    ? restaurant.value.medias
        .map((media) => {
          const path = media.formats?.[0]?.path;
          return path ? { '@type': 'ImageObject', url: path.toString() } : null;
        })
        .filter((obj): obj is { '@type': 'ImageObject'; url: string } => obj !== null)
    : [],
  priceRange: `${restaurant.value.averagePrice?.toString()}${restaurant.value.currencySymbol}`,
  review: {
    '@type': 'Review',
    author: {
      '@type': 'Person',
      name: bestReview.value?.reviewer?.displayName || ''
    },
    reviewRating: {
      '@type': 'Rating',
      bestRating: 5,
      ratingValue: StarRating[bestReview.value?.starRating as keyof typeof StarRating]
    }
  },
  servesCuisine: restaurant.value.foodType,
  telephone: `+${restaurant.value.phonePrefix}${restaurant.value.phone}`,
  url: `${config.public.baseUrl}${route.path}`
}));

onMounted(() => {
  openPreselectedBooking();
});

whenever(
  () => userStore.userLoadDone,
  () => {
    if (userStore.userLoadDone) {
      pageEvent();
    }
  }
);

onMounted(() => {
  if (userStore.userLoadDone) {
    pageEvent();
  }
});
</script>
