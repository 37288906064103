<template>
  <div class="Banner-auth">
    <div class="Banner-auth__content">
      <ux-atoms-typo as="p" class="Banner-auth__title" color="dark">{{ title }}</ux-atoms-typo>
      <ux-atoms-typo as="p">{{ description }}</ux-atoms-typo>
    </div>
    <ux-atoms-link :text="link.text" :to="link.href" @click="gtmBannerAuthEvent" class="Banner-auth__link" v-if="link" />
  </div>
</template>
<script setup lang="ts">
import { gtmEvent } from '~/helpers/gtm';

import { LinkProps } from '../../atoms/Link/Link.vue';

export interface BannerAuthProps {
  description: string;
  link?: LinkProps;
  step: number;
  title: string;
}

const props = defineProps<BannerAuthProps>();

const gtmBannerAuthEvent = () => {
  gtmEvent('cta_authentification', {
    bloc_name: `step ${props.step}`,
    pagename: 'restaurantsandbars::booking::steps'
  });
};
</script>
<style lang="scss" scoped>
@use 'BannerAuth.scss';
</style>
