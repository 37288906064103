<template>
  <div :class="['Booking-notice', { 'Booking-notice--expanded': showScrollContent }]">
    <ux-atoms-typo as="p" class="Booking-notice__main-description" color="grey-1" variant="text-regular">
      <i18n-t :keypath="legalDataKey" v-if="legalDataKey">
        <button
          :class="['Booking-notice__toggle', `Booking-notice__toggle--${variant}`]"
          @click="toggleScrollContent"
          aria-controls="description"
          type="button"
          v-if="termsAndConditions"
        >
          {{ $t(`${legalDataKey}TextLink`) }}
        </button>
        <ux-atoms-typo
          :text="$t(`${legalDataKey}TextLink`)"
          as="p"
          class="Booking-notice__main-description"
          color="grey-1"
          v-else
          variant="text-regular"
        />
      </i18n-t>
    </ux-atoms-typo>
    <div class="Booking-notice__prepayment-information" v-if="variant === BookingNoticeVariant.Deposit && props.chargeAmount">
      <ux-atoms-typo :text="$t('ux.molecules.bookingNotice.depositLabel')" as="p" color="grey-1" variant="text-regular" />
      <ux-atoms-typo :text="price" as="p" class="Booking-notice__prepayment-information__price" color="grey-1" variant="text-regular" />
    </div>
    <ux-atoms-typo :text="termsAndConditions" as="p" class="Booking-notice__terms" color="grey-1" v-show="showScrollContent" variant="text-regular" />
  </div>
</template>
<script lang="ts" setup>
import { BookingNoticeVariant } from './types';

export interface BookingNoticeProps {
  chargeAmount?: number | string;
  currencySymbol?: null | string;
  termsAndConditions: string;
  variant: BookingNoticeVariant;
}

const props = withDefaults(defineProps<BookingNoticeProps>(), {
  chargeAmount: undefined,
  currencySymbol: '',
  termsAndConditions: '',
  variant: BookingNoticeVariant.Deposit
});

const showScrollContent = ref(false);

const toggleScrollContent = () => {
  showScrollContent.value = !showScrollContent.value;
};
const legalDataKey = computed(() => `ux.molecules.bookingNotice.${props.variant}Information`);

const price = computed(() => {
  return `${props.chargeAmount}${props.currencySymbol}`;
});
</script>

<style lang="scss" scoped>
@use 'BookingNotice.scss';
</style>
