export const detectElements = (elements: string[]): string | undefined => {
  for (const elementId of elements) {
    const element = document.getElementById(elementId);
    if (element && isInViewport(element)) {
      return elementId;
    }
  }
};

export const isInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
};
