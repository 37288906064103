export function extractTranslation<T>(data: Record<string, any>, keyPath?: null | string): T | null {
  if (!keyPath || keyPath === '') {
    return null;
  }
  const keys = keyPath.split('.');
  let current: any = data;

  for (const key of keys) {
    if (current[key] === undefined) {
      return null;
    }
    current = current[key];
  }

  if (current?.body?.static) {
    return current.body.static as T;
  }

  return typeof current === 'string' ? (current as T) : null;
}
