import { SingleAvailabilitiesQuery, SingleAvailabilitiesQueryVariables, useSingleAvailabilitiesLazyQuery } from '~/graphql';

export function useRestaurantAvailabilities(queryParameters: SingleAvailabilitiesQueryVariables) {
  const store = useBookRestaurantStore();

  const data = ref<SingleAvailabilitiesQuery>();

  const loadingLazySingleAvailabilities = ref(false);

  const handleAvailabilitiesParamChange = (queryParametersUpdated?: SingleAvailabilitiesQueryVariables) => {
    store.showOverlay();
    const { load, onError, onResult } = useSingleAvailabilitiesLazyQuery(queryParametersUpdated ?? queryParameters);

    onResult((response) => {
      loadingLazySingleAvailabilities.value = true;
      if (response.loading) {
        return;
      }
      data.value = response.data;
      loadingLazySingleAvailabilities.value = false;
    });

    onError(() => {
      loadingLazySingleAvailabilities.value = false;
    });

    load();

    store.refreshTimes();
    store.hideOverlay();
  };

  return {
    data,
    handleAvailabilitiesParamChange,
    loadingLazySingleAvailabilities
  };
}
