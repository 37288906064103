<template>
  <div class="Restaurant-availabilities">
    <ux-molecules-restaurant-availabilities-content
      :display-restaurant-actions="displayRestaurantActions"
      :has-partners="hasPartners"
      :identifiers="identifiers"
      :initial-availabilities="initialAvailabilities"
      :phone-number="phoneNumber"
      class="Restaurant-availabilities__content"
      v-if="(phoneNumber || hasPartners) && !hasMatchingWidget"
    />
    <ux-molecules-restaurant-actions
      :booking-widget-url="bookingWidgetUrl"
      :charge-amount="chargeAmount"
      :currency-symbol="currencySymbol"
      :external-menu-url="externalMenuUrl"
      :has-matching-widget="hasMatchingWidget"
      :has-partners="hasPartners"
      :menu-type="menuType"
      :menus="menus"
      :offers="offers"
      :partner-id="partnerId"
      :terms-and-conditions="termsAndConditions"
      v-if="displayRestaurantActions"
    />
  </div>
</template>
<script setup lang="ts">
import { MenuType, RestaurantIdentifier, RestaurantMenu, RestaurantOffers, SingleAvailabilitiesQuery } from '~/graphql';
import { partnerWidgetMatch } from '~/helpers/partnerWidget';

export interface RestaurantAvailabilitiesProps {
  bookingWidgetUrl?: null | string;
  chargeAmount?: number;
  currencySymbol?: null | string;
  externalMenuUrl: null | string;
  hasPartners?: boolean;
  identifiers?: RestaurantIdentifier[] | null;
  initialAvailabilities?: {
    data: SingleAvailabilitiesQuery['singleAvailabilities'] | null;
    loading: boolean;
  };
  menuType: MenuType | null;
  menus?: RestaurantMenu[] | null;
  offers?: RestaurantOffers[];
  partnerId?: string;
  phone?: string;
  phonePrefix?: string;
  termsAndConditions?: string;
}

const props = withDefaults(defineProps<RestaurantAvailabilitiesProps>(), {
  bookingWidgetUrl: undefined,
  chargeAmount: undefined,
  currencySymbol: undefined,
  externalMenuUrl: undefined,
  hasPartners: undefined,
  identifiers: undefined,
  initialAvailabilities: () => ({
    data: null,
    loading: true
  }),
  menuType: undefined,
  menus: undefined,
  offers: undefined,
  partnerId: undefined,
  phone: undefined,
  phonePrefix: undefined,
  termsAndConditions: undefined
});

const phoneNumber = computed(() => {
  if (props.phone && props.phonePrefix) {
    return `+${props.phonePrefix}${props.phone}`;
  }
  return null;
});

const hasMatchingWidget = computed(() => partnerWidgetMatch(props.partnerId, props.bookingWidgetUrl));

const hasExternalMenu = computed(
  () => Boolean(props.menuType === MenuType.External && props.externalMenuUrl) || (props.menuType === MenuType.Api && props.menus?.length === 1)
);
const hasMenus = computed(() => props.menuType === MenuType.Api && props.menus && props.menus.length > 1);
const displayRestaurantActions = computed(() => props.hasPartners || props.menuType === MenuType.MenuModo || hasExternalMenu.value || hasMenus.value);
</script>
<style lang="scss" scoped>
@use 'RestaurantAvailabilities.scss';
</style>
