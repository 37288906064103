<template>
  <ux-atoms-typo :id="id" as="h2" class="py-2" color="dark" variant="expressive-heading-04">
    {{ $t('ux.organismes.customersReviews.title') }}
  </ux-atoms-typo>
  <ux-molecules-average-rating-block :average-rating="averageRating" :review-count="reviewCount" v-if="averageRating" />
  <ux-molecules-customer-review
    :comment="review.comment"
    :full-name="review.fullName"
    :key="`customer-${review.fullName}-${index}`"
    :publication-date="review.publicationDate"
    :rating="review.rating"
    :restaurant-reply="review.restaurantReply"
    v-for="(review, index) in customersReviews"
  />
  <div class="CustomersReviews__button">
    <ux-atoms-button
      :label="$t('ux.organismes.customersReviews.showMoreButton.label', { reviewCount })"
      @click="handleDisplayReviews"
      size="m"
      v-if="customersReviews.length < props.reviews.length"
      variant="outlined"
    />
  </div>
</template>

<script lang="ts" setup>
import { CustomerReview } from '~/graphql';

import { StarRating } from './type';

interface Props {
  averageRating?: null | number;
  id: string;
  reviewCount: number;
  reviews: CustomerReview[];
}

const props = withDefaults(defineProps<Props>(), {
  averageRating: undefined,
  reviewCount: 0,
  reviews: () => []
});

const displayableRating = ref(4);

const handleDisplayReviews = () => {
  if (customersReviews.value.length < props.reviews.length) {
    displayableRating.value = displayableRating.value + 4 > props.reviews.length ? props.reviews.length : displayableRating.value + 4;
  }
};

const customersReviews = computed(() =>
  props.reviews
    .map((review) => ({
      comment: review.comment as string,
      fullName: review.reviewer?.displayName as string,
      publicationDate: (review.updateTime ?? review.createTime) as string,
      rating: StarRating[review.starRating as keyof typeof StarRating],
      restaurantReply: review.reviewReply as { comment: string; updateTime: string }
    }))
    .slice(0, displayableRating.value)
);
</script>

<style lang="scss" scoped>
@use './CustomersReviews.scss';
</style>
