<template>
  <div class="Customer-form">
    <div class="Customer-form__header">
      <span class="Customer-form__title" v-if="!userStore.isLogged">{{ t('ux.organismes.customerForm.title') }}</span>
      <p class="Customer-form__description">{{ $t('ux.organismes.customerForm.description') }}</p>
    </div>
    <fieldset class="Customer-form__fieldset">
      <div class="Customer-form__name-container">
        <ux-atoms-form-text-input
          :error="form.firstName.error"
          :error-text="form.firstName.errorText"
          :id="form.firstName.name"
          :label="form.firstName.label"
          :name="form.firstName.name"
          required
          v-model="form.firstName.value"
        />
        <ux-atoms-form-text-input
          :error="form.lastName.error"
          :error-text="form.lastName.errorText"
          :id="form.lastName.name"
          :label="form.lastName.label"
          :name="form.lastName.name"
          required
          v-model="form.lastName.value"
        />
      </div>
      <ux-atoms-form-text-input
        :error="form.email.error"
        :error-text="form.email.errorText"
        :id="form.email.name"
        :label="form.email.label"
        :name="form.email.value"
        accessory-left="mail"
        required
        type="email"
        v-model="form.email.value"
      />
      <ux-atoms-form-phone-number-input
        :error="form.phoneNumber.error"
        :error-text="form.phoneNumber.errorText"
        :id="form.phoneNumber.name"
        :name="form.phoneNumber.name"
        @validity-changed="updatePhoneNumberValidity"
        v-model="form.phoneNumber.value"
      />
      <ux-atoms-form-field-set :id="form.hotelRoom.name" :legend="form.hotelRoom.label" class="Customer-form__form-radio-group" variant="booking">
        <ux-atoms-form-radio-input
          :id="`${form.hotelRoom.name}-${t('ux.organismes.customerForm.form.hotelRoom.yes')}`"
          :label="t('ux.organismes.customerForm.form.hotelRoom.yes')"
          :name="form.hotelRoom.name"
          :value="true"
          v-model="form.hotelRoom.value"
        />
        <ux-atoms-form-radio-input
          :id="`${form.hotelRoom.name}-${t('ux.organismes.customerForm.form.hotelRoom.no')}`"
          :label="t('ux.organismes.customerForm.form.hotelRoom.no')"
          :name="form.hotelRoom.name"
          :value="false"
          v-model="form.hotelRoom.value"
        />
      </ux-atoms-form-field-set>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { EMAIL_REGEX } from '~/core/constants';
import { gtmEvent } from '~/helpers/gtm';
import { extractTranslation } from '~/helpers/i18n';
import { useUserStore } from '~/stores/userStore';

interface FormField {
  defaultLabelKey?: string;
  error?: boolean;
  errorText?: string;
  label?: string;
  name: string;
  validator?: (value: any) => string;
  value: any;
}

interface Form {
  email: FormField;
  firstName: FormField;
  hotelRoom: FormField;
  lastName: FormField;
  phoneNumber: FormField;
}

const { messages, t } = useI18n();

const userStore = useUserStore();

const requiredErrorLabel = t('ux.organismes.customerForm.form.requiredError');

const form = reactive<Form>({
  email: {
    error: false,
    errorText: '',
    label: t('ux.organismes.customerForm.form.email.label'),
    name: t('ux.organismes.customerForm.form.email.name'),
    validator: (value: string) => {
      if (!value) {
        return requiredErrorLabel;
      }
      if (!EMAIL_REGEX.test(value)) {
        return t('ux.organismes.customerForm.form.email.invalidError');
      }
      return '';
    },
    value: userStore?.bookingForm?.email ?? userStore.emailContact?.email ?? ''
  },
  firstName: {
    error: false,
    errorText: '',
    label: t('ux.organismes.customerForm.form.firstName.label'),
    name: t('ux.organismes.customerForm.form.firstName.name'),
    validator: (value: string) => {
      if (!value) {
        return requiredErrorLabel;
      }
      return '';
    },
    value: userStore?.bookingForm?.firstName ?? userStore.firstName ?? ''
  },
  hotelRoom: {
    label: t('ux.organismes.customerForm.form.hotelRoom.label'),
    name: t('ux.organismes.customerForm.form.hotelRoom.name'),
    value: userStore?.bookingForm?.hotelRoom ?? null
  },
  lastName: {
    error: false,
    errorText: '',
    label: t('ux.organismes.customerForm.form.lastName.label'),
    name: t('ux.organismes.customerForm.form.lastName.name'),
    validator: (value: string) => {
      if (!value) {
        return requiredErrorLabel;
      }
      return '';
    },
    value: userStore?.bookingForm?.lastName ?? userStore.lastName ?? ''
  },
  phoneNumber: {
    error: false,
    errorText: '',
    name: t('ux.organismes.customerForm.form.phoneNumber.name'),
    validator: (value: string): string => {
      if (!value) {
        return 'ux.organismes.customerForm.form.requiredError';
      }
      return form.phoneNumber.error || !value.startsWith('+') ? 'ux.organismes.customerForm.form.phoneNumber.invalidError' : '';
    },
    value:
      userStore?.bookingForm?.phoneNumber ??
      (userStore.phoneNumber?.phonePrefix && userStore.phoneNumber.phoneNumber
        ? `${userStore.phoneNumber.phonePrefix} ${userStore.phoneNumber.phoneNumber}`
        : '')
  }
});

function updatePhoneNumberValidity(isValid: boolean | undefined) {
  if (isValid !== undefined) {
    form.phoneNumber.error = !isValid;
  }
}

const validateForm = () => {
  let valid = true;
  let firstErrorField: null | string = null;

  (Object.keys(form) as (keyof typeof form)[]).forEach((key) => {
    const field = form[key];
    const errorText = field.validator && field.validator(field.value);

    if (errorText) {
      field.error = true;
      field.errorText = errorText;
      valid = false;
      if (!firstErrorField) {
        firstErrorField = field.name;
      }
    } else {
      field.error = false;
      field.errorText = '';
    }

    if (field.error) {
      const defaultLabel: null | string = extractTranslation<string>(messages.value.en, field.defaultLabelKey);
      gtmEvent('booking_form_interact', {
        error_field: defaultLabel ?? field.name,
        error_type: field.errorText === requiredErrorLabel ? 'blank' : 'invalid value',
        form_action: 'error',
        pagename: 'restaurantsandbars::booking::steps'
      });
    }
  });

  const formData = {
    email: form.email.value,
    firstName: form.firstName.value,
    hotelRoom: form.hotelRoom.value,
    lastName: form.lastName.value,
    phoneNumber: form.phoneNumber.value
  };

  return { firstErrorField, formData, valid };
};

defineExpose({ validateForm });
</script>

<style lang="scss" scoped>
@use 'CustomerForm.scss';
</style>
