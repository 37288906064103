<template>
  <div class="Restaurant-toolbar Grid-layout Grid-layout--y-space">
    <ux-atoms-link :aria-label="ariaLabel" :to="linkToPage" class="Restaurant-toolbar__link">
      <ux-atoms-icon name="arrow-left" size="s" />
      <ux-atoms-typo as="span" variant="text-small">
        {{ $t(breadcrumbJsonRef, { city: capitalizedBreadcrumb }) }}
      </ux-atoms-typo>
    </ux-atoms-link>
  </div>
</template>
<script setup lang="ts">
import { capitalizeFirstLetter } from '~/helpers/capitaliseFirstLetter';
import { sanitizeString } from '~/helpers/sanitizeString';
import { defaultDateQueryParams, defaultGuestQueryParams } from '~/helpers/urlQueryParams';

export interface GridImagesProps {
  cityName: string;
  citySlug: string;
  countrySlug: string;
}

const props = withDefaults(defineProps<GridImagesProps>(), {
  cityName: undefined,
  citySlug: undefined,
  countrySlug: undefined
});

const localePath = useLocalePath();
const route = useRoute();
const queryParams = route.query;
const { searchParams } = useSearchRestaurant();
const { t } = useI18n();

const capitalizedBreadcrumb = computed(() => {
  const result: string = sanitizeString(capitalizeFirstLetter(queryParams.poi?.toString() ?? props.cityName?.toLowerCase()));
  return result.slice(0, 55) + (result.length > 55 ? '...' : '');
});

const ariaLabel = computed(() => {
  return `${t('ux.molecules.restaurantToolbar.goBack')} - ${t(breadcrumbJsonRef.value, { city: capitalizedBreadcrumb.value })}`;
});

const breadcrumbJsonRef = computed(() => {
  return 'ux.molecules.restaurantToolbar.restaurantsIn' + (queryParams.poi ? '.fromPoiPage' : '.fromOtherPage');
});

const linkToPage = computed(() => {
  const pathName = queryParams.poi ? 'point-of-interest-slug-map' : 'country-city-map';

  const params = queryParams.poi
    ? {
        slug: queryParams.poi as string
      }
    : {
        city: props.citySlug,
        country: props.countrySlug
      };

  return localePath({
    name: pathName,
    params,
    query: {
      date: defaultDateQueryParams(searchParams.value.date),
      guests: defaultGuestQueryParams(searchParams.value.groupSize),
      poi: queryParams.poi ?? undefined
    }
  });
});
</script>
<style lang="scss" scoped>
@use 'RestaurantToolbar.scss';
</style>
