<template>
  <div class="CustomerReview__main">
    <ux-atoms-typo as="p" class="CustomerReview__author" color="dark" variant="text-heading-01">
      {{ fullName }}
    </ux-atoms-typo>
    <div class="CustomerReview__rating">
      <ux-atoms-rating :stars="rating" :variant="RatingVariant.OnlyStars" />
      <ux-atoms-typo as="p" class="CustomerReview__date" color="dark" variant="text-regular">
        {{ formatSinceDate(t, publicationDate) }}
      </ux-atoms-typo>
    </div>
    <div class="CustomerReview__comment">
      <ux-atoms-typo
        :class="[{ 'CustomerReview__limited-text': commentMultiLine }]"
        :id="`customerReview-originalComment-${fullName}-${publicationDate}`"
        as="p"
        color="dark"
        v-if="originalComment && commentComputed"
      >
        {{ commentComputed }}
      </ux-atoms-typo>
      <ux-atoms-button @click="() => toggleExpandText('comment')" class="CustomerReview__comment--show-more" v-if="showMoreComment" variant="link">{{
        showMoreLabel(commentMultiLine)
      }}</ux-atoms-button>
      <div class="CustomerReview__comment--reply" v-if="restaurantReply && restaurantReply.comment">
        <div class="CustomerReview__comment--owner">
          <ux-atoms-typo as="p" class="" color="dark" variant="text-heading-01">{{
            $t('ux.organismes.customersReviews.restaurantReply.title')
          }}</ux-atoms-typo>
          <ux-atoms-typo as="p" class="" color="dark" variant="text-regular">
            {{ formatSinceDate(t, restaurantReply.updateTime) }}
          </ux-atoms-typo>
        </div>
        <ux-atoms-typo
          :class="[{ 'CustomerReview__limited-text': replyMultiLine }]"
          :id="`restaurantReply-to-${fullName}-${restaurantReply.updateTime}`"
          as="p"
          color="dark"
        >
          {{ restaurantReply.comment }}
        </ux-atoms-typo>
        <ux-atoms-button @click="() => toggleExpandText('reply')" class="CustomerReview__comment--show-more" v-if="showMoreReply" variant="link">{{
          showMoreLabel(replyMultiLine)
        }}</ux-atoms-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatSinceDate } from '@accor/foodandbeverage-front/helpers/date';

import { RatingVariant } from '~/core';

interface Props {
  comment?: string;
  fullName?: string;
  publicationDate?: string;
  rating?: number;
  restaurantReply?: {
    comment: string;
    updateTime: string;
  };
}

const props = defineProps<Props>();

const original = '(Original)';
const translated = '(Translated by Google)';

const { t } = useI18n();

const commentMultiLine = ref<boolean>(false);
const replyMultiLine = ref<boolean>(false);
const showMoreComment = ref<boolean>(false);
const showMoreReply = ref<boolean>(false);

const showMoreLabel = (state: boolean) => {
  return state ? t('ux.organismes.customersReviews.showMore.more') : t('ux.organismes.customersReviews.showMore.less');
};

const originalComment = computed(() => {
  if (!props.comment) {
    return undefined;
  }

  if (props.comment.includes(original)) {
    return props.comment.split(original)[1];
  }

  if (![original, translated].includes(props.comment)) {
    return props.comment;
  }
});

const translatedComment = computed(() => {
  if (!props.comment) {
    return undefined;
  }

  if (props.comment.includes(translated) && originalComment.value) {
    return props.comment.split(original)[0].replace(originalComment.value, '');
  }

  return undefined;
});

const commentComputed = computed(() => {
  if (translatedComment.value) {
    return `${originalComment.value}\n${translatedComment.value}`;
  }

  return originalComment.value;
});

const toggleExpandText = (target: 'comment' | 'reply') => {
  switch (target) {
    case 'comment':
      commentMultiLine.value = !commentMultiLine.value;
      break;
    case 'reply':
      replyMultiLine.value = !replyMultiLine.value;
      break;
    default:
      return null;
  }
};

const checkIfMultilineElements = () => {
  const commentElement = document.getElementById(`customerReview-originalComment-${props.fullName}-${props.publicationDate}`);
  if (commentElement) {
    const lineHeight = parseInt(window.getComputedStyle(commentElement).lineHeight, 10);
    const elementHeight = commentElement.offsetHeight;
    commentMultiLine.value = elementHeight > lineHeight * 2;
    showMoreComment.value = commentMultiLine.value;
  }

  const replyElement = document.getElementById(`restaurantReply-to-${props.fullName}-${props.restaurantReply?.updateTime}`);
  if (replyElement) {
    const lineHeight = parseInt(window.getComputedStyle(replyElement).lineHeight, 10);
    const elementHeight = replyElement.offsetHeight;
    replyMultiLine.value = elementHeight > lineHeight * 2;
    showMoreReply.value = replyMultiLine.value;
  }
};

onMounted(() => {
  checkIfMultilineElements();
});
</script>

<style lang="scss" scoped>
@use './CustomerReview.scss';
</style>
