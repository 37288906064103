<template>
  <div class="booking-offer-summary">
    <div>
      <div class="Booking-offer-summary__head">
        <ux-atoms-typo
          :text="$t('ux.molecules.bookingOfferSummary.title')"
          as="p"
          class="Booking-offer-summary__title"
          color="dark"
          variant="text-regular"
        />
        <ux-atoms-button
          :label="$t('ux.molecules.bookingOfferSummary.edit')"
          @button::click="$emit('bookingOfferSummary::goBack')"
          class="Booking-offer-summary__detail-link"
          variant="link"
        />
      </div>
      <ux-atoms-typo
        :text="restaurantName"
        as="p"
        class="Booking-offer-summary__subtitle"
        color="light"
        v-if="restaurantName"
        variant="text-regular"
      />
    </div>
    <div>
      <div class="Booking-offer-summary__details">
        <div class="Booking-offer-summary__details-item" v-if="date">
          <ux-atoms-icon name="calendar" size="m" />
          <ux-atoms-typo :text="computedDate" as="span" class="Booking-offer-summary__date" variant="text-regular" />
        </div>
        <div class="Booking-offer-summary__details-item" v-if="guests">
          <ux-atoms-icon name="occupant-adult" size="m" />
          <ux-atoms-typo
            :text="$t('ux.molecules.bookingOfferSummary.guests', { n: guests })"
            as="span"
            class="booking-offer-summary__date"
            variant="text-regular"
          />
        </div>
        <div class="Booking-offer-summary__details-item" v-if="offerName">
          <ux-atoms-icon name="promotion" size="m" />
          <ux-atoms-typo :text="offerName" as="span" class="Booking-offer-summary__date" variant="text-regular" />
          <ux-atoms-typo
            :text="`${offerPrice} ${currencySymbol}`"
            as="span"
            class="Booking-offer-summary__price"
            color="dark"
            v-if="offerPrice && isPrepayment"
            variant="text-regular"
          />
        </div>
      </div>
      <div class="Booking-offer-summary__footer" v-if="isPrepayment && prepaymentPrice">
        <ux-atoms-typo
          :text="$t('ux.molecules.bookingOfferSummary.totalPrepayement')"
          as="span"
          class="Booking-offer-summary__footer-label"
          color="light"
          variant="text-regular"
        />
        <ux-atoms-typo :text="`${prepaymentPrice} ${currencySymbol}`" as="span" class="Booking-offer-summary__footer-price" variant="text-regular" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { dateClassic } from '~/helpers/date';

export interface BookingOfferSummaryProps {
  currencySymbol?: null | string;
  date?: null | string;
  guests?: null | number;
  isPrepayment?: boolean | null;
  offerName?: null | string;
  offerPrice?: null | number;
  prepaymentPrice?: null | number;
  restaurantName?: null | string;
  time?: null | string;
}

const { t } = useI18n();

const props = withDefaults(defineProps<BookingOfferSummaryProps>(), {
  currencySymbol: undefined,
  date: undefined,
  guests: undefined,
  isPrepayment: undefined,
  offerName: undefined,
  offerPrice: undefined,
  prepaymentPrice: undefined,
  restaurantName: undefined,
  time: undefined
});

defineEmits(['bookingOfferSummary::goBack']);

const computedDate = computed(() => {
  return t('ux.molecules.bookingOfferSummary.date', { date: dateClassic(props.date as string), time: props.time });
});
</script>
<style lang="scss" scoped>
@use 'BookingOfferSummary.scss';
</style>
