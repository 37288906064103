<template>
  <div class="Restaurant-offer">
    <ux-atoms-image :alt="title" :src="src" class="Restaurant-offer__image" height="62" width="62" />
    <div class="Restaurant-offer__content">
      <ux-atoms-typo :text="title" as="p" color="dark" variant="text-heading-01" />
      <ux-atoms-typo :text="description" as="p" variant="text-small" />
      <div class="Restaurant-offer__tags">
        <ux-atoms-tag :label="$t('ux.molecules.restaurantOffer.apOffer')" padding-size="medium" v-if="isAccorPlus && userStore.APZone" variant="ap" />
      </div>
    </div>
    <button @click="toggleModal($event)" class="Restaurant-offer__button">
      <ux-atoms-icon name="chevron-right" size="m" />
    </button>
  </div>
</template>
<script lang="ts" setup>
export interface RestaurantOfferProps {
  description?: string;
  id?: string;
  isAccorPlus?: boolean;
  src?: string;
  title?: string;
}

const props = withDefaults(defineProps<RestaurantOfferProps>(), {
  description: undefined,
  id: undefined,
  src: undefined,
  title: undefined
});

const userStore = useUserStore();

const emit = defineEmits(['restaurantOffer::openModal']);

const toggleModal = (e: Event) => {
  emit('restaurantOffer::openModal', e, props.id);
};
</script>
<style lang="scss" scoped>
@use 'RestaurantOffer.scss';
</style>
