<template>
  <div class="Booking-funnel-credit-card">
    <ux-molecules-booking-offer-summary
      :currency-symbol="restaurant?.currencySymbol"
      :date="availability?.date"
      :guests="guests"
      :is-prepayment="offer?.requireDeposit"
      :offer-name="offer?.name"
      :offer-price="offer?.price"
      :prepayment-price="prepaymentAmount"
      :restaurant-name="restaurant?.name"
      :time="availability?.slot"
      @booking-offer-summary::go-back="goBack"
    />
    <ux-atoms-typo
      :text="$t('ux.molecules.bookingFunnelCreditCard.creditCardLabel')"
      as="label"
      class="Booking-funnel-credit-card__label"
      color="dark"
      variant="text-medium"
    />

    <div class="Booking-funnel-credit-card__card-information">
      <div>
        <ux-atoms-typo
          :text="$t('ux.molecules.bookingFunnelCreditCard.cardNumberLabel')"
          as="label"
          class="Booking-funnel-credit-card__input-label"
          for="card-number-element"
        />
        <div class="Booking-funnel-credit-card__input" id="card-number-element"></div>
        <div class="Booking-funnel-credit-card__input-error" id="card-number-errors" role="alert">
          {{ cardNumberError }}
        </div>
      </div>
      <div class="Booking-funnel-credit-card__additional-information">
        <div class="Booking-funnel-credit-card__input-wrapper">
          <ux-atoms-typo
            :text="$t('ux.molecules.bookingFunnelCreditCard.dateLabel')"
            as="label"
            class="Booking-funnel-credit-card__input-label"
            for="card-expiry-element"
          />
          <div class="Booking-funnel-credit-card__input" id="card-expiry-element"></div>
          <div class="Booking-funnel-credit-card__input-error">
            {{ cardExpiryError }}
          </div>
        </div>
        <div class="Booking-funnel-credit-card__input-wrapper">
          <ux-atoms-typo
            :text="$t('ux.molecules.bookingFunnelCreditCard.cvcLabel')"
            as="label"
            class="Booking-funnel-credit-card__input-label"
            for="card-cvc-element"
          />
          <div class="Booking-funnel-credit-card__input" id="card-cvc-element"></div>
          <div class="Booking-funnel-credit-card__input-error">
            {{ cardCvcError }}
          </div>
        </div>
        <div class="Booking-funnel-credit-card__input-wrapper">
          <ux-atoms-typo
            :text="$t('ux.molecules.bookingFunnelCreditCard.zipCodeLabel')"
            as="label"
            class="Booking-funnel-credit-card__input-label"
            for="card-zipcode-element"
          />
          <input
            :class="['Booking-funnel-credit-card__input', { 'Booking-funnel-credit-card__input--error': zipCodeError }]"
            id="card-zipcode-element"
            required
            v-model="zipCode"
            zipCodeError
          />
          <div class="Booking-funnel-credit-card__input-error" v-if="zipCodeError">
            {{ zipCodeError }}
          </div>
        </div>
      </div>
      <ux-atoms-form-checkbox-input class="Booking-funnel-credit-card__checkbox" v-model="conditionAccepted">
        <i18n-t class="Booking-funnel-credit-card__checkbox-label" keypath="ux.molecules.bookingFunnelCreditCard.creditCardConditionLabel">
          <ux-atoms-link :to="$t('ux.molecules.bookingFunnelCreditCard.termsOfUseLink')" target="_blank" underline>
            {{ $t('ux.molecules.bookingFunnelCreditCard.termsOfUse') }}
          </ux-atoms-link>
          <ux-atoms-link :to="'#'" @click="showSalesConditions" underline>
            {{ $t('ux.molecules.bookingFunnelCreditCard.restaurantSalesConditionsLabel') }}
          </ux-atoms-link>
        </i18n-t>
      </ux-atoms-form-checkbox-input>
      <ux-atoms-typo
        :text="restaurant?.termsAndConditions"
        as="p"
        class="Booking-funnel-credit-card__information"
        color="light"
        v-if="displaySalesConditions"
        variant="text-small"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { AvailabilitySlot, Restaurant, RestaurantOffers } from '~/graphql';
export interface BookingFunnelCreditCardProps {
  availability?: AvailabilitySlot | null;
  guests?: number;
  offer?: RestaurantOffers | null;
  prepaymentAmount: number;
  restaurant: Restaurant;
}

withDefaults(defineProps<BookingFunnelCreditCardProps>(), {
  availability: undefined,
  guests: undefined,
  offer: undefined,
  prepaymentAmount: undefined,
  restaurant: undefined
});

const zipCode = ref('');
const zipCodeError = ref('');
const conditionAccepted = ref(false);
const { t } = useI18n();
const { cardCvcError, cardExpiryError, cardNumberError } = useStripe();

const emit = defineEmits(['bookingFunnelCreditCard::goBack', 'bookingFunnelCreditCard::validationChanged']);

const displaySalesConditions = ref(false);

const goBack = () => {
  emit('bookingFunnelCreditCard::goBack');
};

const showSalesConditions = () => {
  displaySalesConditions.value = !displaySalesConditions.value;
};

watch(
  () => zipCode.value,
  () => {
    if (!zipCode.value) {
      zipCodeError.value = t('ux.molecules.bookingFunnelCreditCard.zipCodeError');
    } else {
      zipCodeError.value = '';
    }
    checkValidation();
  }
);

const validateForm = () => {
  let valid = true;

  if (cardCvcError.value || cardExpiryError.value || cardNumberError.value || !zipCode.value || !conditionAccepted.value) {
    valid = false;
  }

  return { valid, zipCode: zipCode.value };
};

const checkValidation = () => {
  const validationResult = validateForm();
  emit('bookingFunnelCreditCard::validationChanged', validationResult.valid);
};

watch([cardCvcError, cardExpiryError, cardNumberError, conditionAccepted], () => {
  checkValidation();
});

defineExpose({ validateForm });
</script>
<style lang="scss" scoped>
@use 'BookingFunnelCreditCard.scss';
</style>
