<template>
  <div class="RestaurantBreadcrumb Grid-layout">
    <nav aria-label="Breadcrumb" class="RestaurantBreadcrumb__nav">
      <ul class="RestaurantBreadcrumb__list">
        <li :key="genUniqueKey(link)" class="RestaurantBreadcrumb__element" v-for="(link, index) in links">
          <ux-atoms-link v-bind="getCurrentProps(link, index, links.length)" variant="text-xsmall">
            {{ link.text }}
          </ux-atoms-link>
          <ux-atoms-icon name="chevron-right" size="s" v-if="index !== links.length - 1" />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { LinkProps } from '../../atoms/Link/Link.vue';

interface Props {
  links: Array<LinkProps>;
}

withDefaults(defineProps<Props>(), {
  links: () => []
});

const genUniqueKey = (link: LinkProps) => {
  return `${link.text}-${link.href}`;
};

const getCurrentProps = (link: LinkProps, currentIndex: number, maxLength: number) => {
  if (currentIndex === maxLength - 1) {
    return {
      ...link,
      'aria-disabled': 'true'
    };
  }

  return link;
};
</script>

<style lang="scss" scoped>
@use './RestaurantBreadcrumb.scss';
</style>
