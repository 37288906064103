import { defineStore } from 'pinia';

import type { bookingObject } from '~/components/ux/molecules/BookingFunnel/types';

import { Restaurant, RestaurantOffers } from '~/graphql';

export const useBookRestaurantStore = defineStore('bookRestaurantStore', () => {
  const selectedRestaurantData = ref<Restaurant>();

  const displayTimes = ref<boolean>(true);

  const preSelectedBooking = ref<bookingObject>();

  const displayOverLay = ref<boolean>(false);

  const displayWarning = ref<boolean>(false);

  const selectedOffer = ref<RestaurantOffers>();

  const modalTarget = ref<HTMLElement | null>(null);

  const xxsModalIsOpen = ref<boolean>(false);

  const refreshTimes = () => {
    displayTimes.value = false;
    nextTick().then(() => {
      displayTimes.value = true;
    });
  };

  const showOverlay = () => {
    displayOverLay.value = true;
  };

  const hideOverlay = () => {
    displayOverLay.value = false;
  };

  const updateSelectedRestaurantData = (restaurantData: Restaurant) => {
    selectedRestaurantData.value = restaurantData;
  };

  const toggleXXSModal = (element?: HTMLElement, bookingSlot?: bookingObject, offer?: RestaurantOffers, warning?: boolean, isOpen?: boolean) => {
    modalTarget.value = element ?? null;
    preSelectedBooking.value = bookingSlot ?? undefined;
    displayWarning.value = warning ?? false;
    selectedOffer.value = offer;
    xxsModalIsOpen.value = isOpen ?? !xxsModalIsOpen.value;
  };

  return {
    displayOverLay,
    displayTimes,
    displayWarning,
    hideOverlay,
    modalTarget,
    preSelectedBooking,
    refreshTimes,
    selectedOffer,
    selectedRestaurantData,
    showOverlay,
    toggleXXSModal,
    updateSelectedRestaurantData,
    xxsModalIsOpen
  };
});
