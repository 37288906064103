import { utcToZonedTime } from 'date-fns-tz';

import { RestaurantOffersValidity } from '~/graphql';
import { formatPrice } from '~/helpers/format';

export function useOffer(
  validityDetails?: RestaurantOffersValidity[],
  priceDetails?: number,
  currencySymbol?: null | string,
  restaurantTimezone?: string,
  guestsDetails?: { max?: number; min?: number }
) {
  const { t } = useI18n();
  const validityRef = ref(validityDetails);
  const priceRef = ref(priceDetails);
  const guestsDetailsRef = ref(guestsDetails);

  const validityDetailsLabel = computed(() => {
    const label: string[] = [];

    if (!validityRef.value) {
      return null;
    }
    for (const validity of validityRef.value) {
      if (!validity.startDate || !validity.endDate) {
        return null;
      }

      const startDateUTC = new Date(validity.startDate);
      const endDateUTC = new Date(validity.endDate);

      const startDate = utcToZonedTime(startDateUTC, restaurantTimezone!);
      const endDate = utcToZonedTime(endDateUTC, restaurantTimezone!);

      const formattedStartDate = useDateFormat(startDate, 'P HH:mm');
      const formattedEndDate = useDateFormat(endDate, 'P HH:mm');

      const translatedDays = validity.frequency?.map((day) => t(`days.${day?.toLowerCase()}`));

      const period = t('ux.molecules.bookingOffer.validityDetails', {
        from: formattedStartDate,
        to: formattedEndDate
      });

      label.push(`${period} ${translatedDays?.length ? ' - ' + translatedDays.join(', ') : ''}`);
    }

    return label;
  });

  const priceLabel = computed(() => {
    if (!priceRef.value) {
      return null;
    }

    const price = formatPrice(priceRef.value);

    if (!price) {
      return null;
    }

    return `${price} ${currencySymbol}`;
  });

  const guestsLabel = computed(() => {
    if (!guestsDetailsRef?.value?.min && !guestsDetailsRef?.value?.max) {
      return null;
    }

    if (guestsDetailsRef?.value?.min && guestsDetailsRef?.value.max) {
      return t('ux.molecules.bookingOffer.guestsDetails', { max: guestsDetailsRef.value.max, min: guestsDetailsRef.value.min });
    }

    if (guestsDetailsRef?.value.max) {
      return t('ux.molecules.bookingOffer.guestsDetailsMax', { max: guestsDetailsRef.value.max });
    }

    if (guestsDetailsRef?.value.min) {
      return t('ux.molecules.bookingOffer.guestsDetailsMin', { min: guestsDetailsRef.value.min });
    }

    return null;
  });

  return {
    guestsLabel,
    priceLabel,
    validityDetailsLabel
  };
}
