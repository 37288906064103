<template>
  <div class="Restaurant-offers-modal-content">
    <ux-atoms-image :alt="title" :src="src" class="Restaurant-offers-modal-content__image" height="335" width="600" />
    <ux-atoms-typo :text="description" as="p" color="grey-1" variant="text-regular" />

    <ux-molecules-booking-offer-booking-offer-detail :details="priceLabel" :text="$t('ux.molecules.bookingOffer.price')" v-if="priceLabel" />
    <ux-molecules-booking-offer-booking-offer-detail
      :details="validityDetailsLabel"
      :text="$t('ux.molecules.bookingOffer.validity')"
      v-if="validityDetailsLabel?.length"
    />
    <ux-molecules-booking-offer-booking-offer-detail :details="guestsLabel" :text="$t('ux.molecules.bookingOffer.guests')" v-if="guestsLabel" />
    <div class="Restaurant-offers-modal-content__footer">
      <ux-atoms-button :label="$t('ux.molecules.bookingOffer.bookATable')" @click="goToBooking" full variant="primary" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { RestaurantOffersValidity } from '~/graphql';

export interface RestaurantOffersModalContentProps {
  currencySymbol?: string;
  description?: string;
  max?: number;
  min?: number;
  priceDetails?: number;
  restaurantTimezone?: string;
  src?: string;
  title?: string;
  validityDetails?: RestaurantOffersValidity[];
}

const props = withDefaults(defineProps<RestaurantOffersModalContentProps>(), {
  currencySymbol: undefined,
  day: undefined,
  description: undefined,
  from: undefined,
  max: undefined,
  min: undefined,
  priceDetails: undefined,
  restaurantTimezone: undefined,
  src: undefined,
  title: undefined,
  to: undefined,
  validityDetails: undefined
});

const emit = defineEmits(['restaurant-offers-modal-content::book-offer']);

const { guestsLabel, priceLabel, validityDetailsLabel } = useOffer(
  props.validityDetails,
  props.priceDetails,
  props.currencySymbol,
  props.restaurantTimezone,
  {
    max: props.max,
    min: props.min
  }
);

const goToBooking = () => {
  emit('restaurant-offers-modal-content::book-offer');
};
</script>
<style lang="scss" scoped>
@use 'RestaurantOffersModalContent.scss';
</style>
